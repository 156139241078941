import React from 'react';
import { useState, useEffect } from 'react';
import './ResponseReactions.scss';

function ResponseReactions({ emojiId, onEmojiClick, option, optionIndex }) {

    let className = 'ResponseReactions';

    const reactions = [
        { name: "Bad", tooltip: "Tooltip 1" },
        { name: "Happy", tooltip: "Tooltip 2" },
        { name: "Go", tooltip: "Tooltip 3" }
    ];

    const handleEmojiClick = (reaction) => {

        console.log('Emoji clicked', reaction);
        onEmojiClick(reaction, option, optionIndex);
    };

    return (
        <div className={className}>
            <div className='reactions-layout'>
                {reactions.map((reaction, index) => {
                    let className = 'reaction';
                    className += ' ' + reaction.name;

                    return (
                        <div className={className} key={reaction.name} onClick={() => handleEmojiClick(reaction)}>
                            <img id={`${emojiId}-${reaction.name}`} src={`reaction-${reaction.name.toLowerCase()}.png`} className='reaction-img' />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default ResponseReactions;
