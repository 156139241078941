import React, { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Back from '../helpers/Back';
import './ThoughtGrid2.scss';

import NegativeGrid from './NegativeGrid';
import ResponseGrid from './ResponseGrid';

import { animated, useSpring, config, useTransition } from '@react-spring/web'
import { CSSTransition } from 'react-transition-group';
import AnimationContext from '../AnimationContext';
import WizardContext from '../WizardContext';
import UI from './UI';
import AssessmentsPeek from './AssessmentsPeek';
import TakeAssessmentScreen from './TakeAssessmentScreen';
import ProgressGrid from './ProgressGrid';

function ThoughtGrid2({ slam, user, changeUser, onAboutClick }) {

    const [viewName, setViewName] = useState("NegativeGrid");

    // Response Loading
    const [options, setOptions] = useState([]);
    const [nextOptions, setNextOptions] = useState([]);
    const [nextStageOptions, setNextStageOptions] = useState([]);
    const [previousOptions, setPreviousOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isFakeLoading, setIsFakeLoading] = useState(false);
    const [isNextLoading, setIsNextLoading] = useState(false);
    const [isNextFresh, setIsNextFresh] = useState(false);
    // EO Respone Loading
    // Reframing Preloading
    const [reframingOptions, setReframingOptions] = useState([]);
    const [isReframingLoading, setIsReframingLoading] = useState(true);
    // EO Reframing Preloading

    const [thought, setThought] = useState(null);
    const [showIntro, setShowIntro] = useState(false);

    // For CSSTransition of Main Content.
    const [inProp, setInProp] = useState(false);
    const nodeRef = useRef(null);

    const [completedSlotMessage, setCompletedSlotMessage] = useState(null);
    const [showCompletedSlotMessage, setShowCompletedSlotMessage] = useState(false);

    const [responseVoice, setResponseVoice] = useState("Explore");

    const [exploreItems, setExploreItems] = useState([]);

    const [pageEffect, setPageEffect] = useState("");
    const [pageEffects, setPageEffects] = useState([]);
    const pageEffectsRef = useRef([]);

    const isNextFreshRef = useRef();
    const nextOptionsRef = useRef();
    const previousOptionsRef = useRef([]);
    const nextOptionsVoiceRef = useRef();
    const responseVoiceRef = useRef("Explore");
    const isExpandLoading = useRef();

    const [activeAssessment, setActiveAssessment] = useState(null);
    const [activeQuestions, setActiveQuestions] = useState(user.active_questions);

    const [responseStage, setResponseStage] = useState(null);
    const [moodMeterValue, setMoodMeterValue] = useState(user.context.moodMeter);
    const [donationsValue, setDonationsValue] = useState(user.context.donationBalance);

    const [moodAnimation, setMoodAnimation] = useState(null);
    const [donationAnimation, setDonationAnimation] = useState(null);

    const [currentDate, setCurrentDate] = useState(new Date);
    const [dropSpaceHeight, setDropSpaceHeight] = useState(null);

    // For optimized state management, probably just need two separate.
    const [responseList1, setResponseList1] = useState([]);
    const [responseList2, setResponseList2] = useState([]);

    const [progressRows, setProgressRows] = useState(user.progress_grid);
    const [progressPlaceholder, setProgressPlaceholder] = useState(null);
    const [isNewThoughtFlow, setIsNewThoughtFlow] = useState(false);
    const [previousExploreCount, setPreviousExploreCount] = useState(0);
    const [startingExploreCount, setStartingExploreCount] = useState(0);

    const exploredChallengeCount = user.thought_log.filter(item => item.status === "Reinforcing").length;

    useEffect(() => {

        // NOTE: DO NOT GATE RIGHT NOW.
        return;

        if (user.is_account_gated) {
            return;
        }

        if (user.is_temporary) {
            const completedThoughtCount = sessionThoughts.filter(thought => thought.status === "Completed").length;

            if (completedThoughtCount >= 3) {
                user.is_account_gated = true;
                user.CreateAccountDrawer = true;
                user.CreateAccountForceRegistration = true;
                changeUser({ ...user });
            }
        }

        // user.CreateAccountDrawer = true;
        // user.CreateAccountForceRegistration = true;
        // changeUser({ ...user });
    }, [user]);

    // useEffect(() => {
    //     setTimeout(() => {
    //     }, 1000);
    // });

    useEffect(() => {


        if (user.isNew && user.popRound === 1) {

        }

        // if (user.isNew && user.popRound === 1) {
        //     setShowIntro(true);
        //     setTimeout(() => {
        //         setShowIntro(false);

        //         user.PickGoalsDrawer = true;
        //         changeUser({ ...user });

        //     }, 13500); // 13500
        // }

        // LANDSLIDE
        // if (user.isNew && user.popRound === 1 &&
        //     !user.hasCompletedIntro) {
        //     setShowIntro(true);
        //     setTimeout(() => {
        //         setShowIntro(false);
        //         user.PickGoalsDrawer = true;
        //         changeUser({ ...user });
        //     }, 1000); // 13500
        // }

        // NOTE: DO NOT GATE YET
        return;

        if (user.is_account_gated) {
            user.CreateAccountDrawer = true;
            user.CreateAccountForceRegistration = true;
            changeUser({ ...user });
        }

    }, []);

    const [sessionThoughts, setSessionThoughts] = useState(slam.slots);
    const [preRating, setPreRating] = useState(null);

    const [responses, setResponses] = useState([]);
    const [strikingLineNumber, setStrikingLineNumber] = useState(0);
    const [strikeWidths, setStrikeWidths] = useState([0, 0, 0]);

    const [asideResponses, setAsideResponses] = useState([]);
    const [asideResponseStage, setAsideResponseStage] = useState(null);

    const [showResponsesDoneButton, setShowResponsesDoneButton] = useState(false);

    const [isThoughtSingleLine, setIsThoughtSingleLine] = useState(null);

    const [isAnimatingCompletedThought, setIsAnimatingCompletedThought] = useState(false);

    const isReframingType = false;

    const [debugs, setDebugs] = useState(["First"]);

    const animations = {
        addPageEffect: (e) => {
            const oldPageEffects = [...pageEffectsRef.current];
            const newPageEffects = [...pageEffectsRef.current, e];

            setPageEffects(newPageEffects);
            pageEffectsRef.current = newPageEffects;
        },

        removePageEffect: (e) => {
            const oldPageEffects = [...pageEffectsRef.current];
            const newPageEffects = pageEffectsRef.current.filter(i => i !== e);

            setPageEffects(newPageEffects);
            pageEffectsRef.current = newPageEffects;
        },
        de: (e) => {
            const newDebugs = [e];

            setDebugs(newDebugs);
        }
    };

    const wizard = {
        pop: (name, domQuery, shift, positions, onClose) => {

        }
    };

    const handleAssessmentBubbleClick = () => {
        setViewName("AssessmentsPeek");
    };

    const handleBackToMainClick = () => {
        setViewName("NegativeGrid");
    };

    const handleProgressCardClick = (option, index) => {
        console.log('handleProgressCardClick', option, index);

        handleThoughtClick(option, false);

        //return;

        const exploreItems = [...option.exploreItems];
        setResponseList1(exploreItems.slice(0, 20));
        setResponseList2(exploreItems.slice(20));

        console.log('handleProgressCardClick', exploreItems);
    };

    const handleThoughtClick = async (thought, isBrandNew) => {

        console.log('qwe handleThoughtClick', thought, isBrandNew);
        user.showPop1 = false;

        if (isBrandNew) {
            console.log('qwe setIsNewThoughtFlow true');
            setIsNewThoughtFlow(true);
            setStartingExploreCount(0);
        } else {
            console.log('qwe setIsNewThoughtFlow false', thought.exploreItems.length);
            setIsNewThoughtFlow(false);
            setStartingExploreCount(thought.exploreItems.length);
        }

        setPreviousExploreCount(thought.exploreItems.length);

        if (false && thought.purpose === "Reframe") {
            setResponseStage("Acknowledge");
            thought.responseStage = "Acknowledge";
        } else {
            setResponseStage("Motivate");
            thought.responseStage = "Motivate";
        }

        Back.recordEvent("ClickedNegativeThought", thought.thought);

        setTimeout(() => {
            //document.querySelector('.SessionGrid').classList.add('HideHeadings');
            animations.addPageEffect("FadeOutMainHeading");
            animations.addPageEffect("FadeOutProgressGrid");
            animations.addPageEffect("FadeOutGroupHeadings");
            animations.addPageEffect("FadeOutStackCard");
            animations.addPageEffect("FadeInProgressGrid");
        }, 30);
        setTimeout(() => {
            //document.querySelector('.SessionGrid .thoughts').classList.add('HideOnEdge2');
        }, 110);
        setTimeout(() => {
            //document.querySelector('.SessionGrid .thoughts').classList.add('HideOnEdge1');
        }, 150);

        const newSessionThoughts = [...sessionThoughts];

        // DEBT: Do clean up from last thought here. Not a great place.
        let previousSlot = sessionThoughts.find(t => t.slide_in === true);
        let previousSlotIndex = sessionThoughts.findIndex(t => t.slide_in === true);
        if (previousSlot) {
            previousSlot.slide_in = false;
            newSessionThoughts[previousSlotIndex] = { ...previousSlot };
            //setSessionThoughts(newSessionThoughts2);
            setSessionThoughts([...newSessionThoughts]);
        }

        const pendingSlot = sessionThoughts.find(t => t.status === "Pending");
        const pendingSlotIndex = sessionThoughts.findIndex(t => t.status === "Pending");

        const newSlot = { ...pendingSlot };
        newSlot.status = "Active";
        newSlot.thought = thought;
        newSlot.row = thought.row;

        if (thought.type === "Reinforce" || thought.status === "Reinforcing") {
            thought.type = "Reinforce";
            thought.status = "Reinforcing";
            thought.isReinforce = true;
            thought.isReinforcing = true;
            thought.isNeutral = false;
            thought.isNegative = false;
        } else {
            thought.type = "Negative";
            thought.status = "Exploring";
            thought.isReinforce = false;
            thought.isReinforcing = false;
            thought.isNeutral = true;
            thought.isNegative = true;
            //thought.isNegative = false;
        }

        if (thought.isReinforce || thought.isFromLog) {
            newSlot.is_hidden = true;
        }
        newSlot.is_hidden = true; // Hiding all for now since we removed PreRating completely

        newSessionThoughts[pendingSlotIndex] = newSlot;

        setSessionThoughts(newSessionThoughts);
        setThought(newSlot.thought);

        console.log('thought exploreItems', thought);
        if (thought.exploreItems) {
            const newExploreItems = thought.exploreItems.map(exploreItem => {
                exploreItem.isNew = false;
                exploreItem.isFading = false;
                return exploreItem;
            });
            setExploreItems(newExploreItems);
            console.log('thought exploreItems set', newExploreItems);
        } else {
            setExploreItems([]);
        }

        console.log('thought click', thought);

        //return;

        loadOptions(newSlot.thought);

        //setPageEffect("FadeOutSessionGrid HideExpandedBackground");

        const delay = 0;

        setTimeout(() => {
            //setPageEffect("FadeOutSessionGrid HideExpandedBackground FadeInExpandedBackground");
            setPageEffect("");
        }, 3000 + delay);



        // Start Fading In Expanded Evidence Background (Tan)
        setTimeout(() => {
            animations.addPageEffect("FadedOutResponseGrid");
        }, 100 + delay);

        setTimeout(() => {
            setViewName("ResponseGrid");
        }, 1000 + delay);

        setTimeout(() => {
            animations.removePageEffect("FadedOutResponseGrid");
        }, 1100 + delay);

        // Slide Session Card Up
        // setTimeout(() => {
        //     expandSessionCard(null, true);
        // }, 600);

        // setTimeout(() => {
        //     setPageEffect("FadeOutSessionGrid HideExpandedBackground FadeInExpandedBackground");
        // }, 1200);

        // Switch To New View "ResponseGrid" Without Visual Tears
        // setTimeout(() => {
        //     setPageEffect("");
        // }, 1000 + delay);

    };

    const handlePostSessionCompleted = (questions) => {
    };

    const handlePreRating = (rating) => {
        Back.recordEvent("ClickedPreRating", rating);

        setPreRating(rating);

        const newEl = copySessionCardToExpand();

        setTimeout(() => {
            setPageEffect("FadeOutSessionGrid HideExpandedBackground");
        }, 100);

        setTimeout(() => {
            setViewName("ResponseGrid");
        }, 600);

        setTimeout(() => {
            expandSessionCard(newEl);
        }, 600 - 250);

        setTimeout(() => {
            setPageEffect("FadeOutSessionGrid HideExpandedBackground FadeInExpandedBackground");
        }, 1200 - 100);

        setTimeout(() => {
            setPageEffect("");
        }, 1900);

    };

    async function delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const handleThoughtCompleted = async (completedSlot, newUser, newThoughtSetId) => {

        console.log('qwe handleThoughtCompleted', newThoughtSetId, completedSlot, newUser, newUser.progress_grid);

        const speedMultiplier = 1;

        const newProgressRows = Object.values(newUser.progress_grid);
        const labeledProgressRows = newProgressRows.map(row => {
            const newItems = row.items.map(item => {
                if (item && item.id === newThoughtSetId) {
                    console.log('qwe labeling completed thought as new', item, item.id, newThoughtSetId);
                    item.isNew = true;
                } else {
                }
                return item;
            });
            row.items = newItems;
            return row;
        });

        const newCompletedThoughtRow = labeledProgressRows.find(row =>
            row.items.find(item => item?.id === newThoughtSetId)
        );
        const newCompletedThought = newCompletedThoughtRow?.items.find(item => item?.id === newThoughtSetId);


        //changeUser({ ...user });


        animations.removePageEffect("FadeOutMainHeading");
        animations.removePageEffect("FadeOutGroupHeadings");
        animations.removePageEffect("FadeOutResponseGrid");

        const isReinforce = completedSlot.thought.type === "Reinforce";

        // Update state. Replace the "active" slot with the "completed" slot in our state.
        const pendingSlotIndex = sessionThoughts.findIndex(t => t.status === "Active");
        let newSessionThoughts = [...sessionThoughts];
        newSessionThoughts[pendingSlotIndex] = { ...completedSlot };
        setSessionThoughts(newSessionThoughts);

        // Switch views behind the scenes while things are faded out on the right side of the screen.
        setViewName("NegativeGrid");
        animations.addPageEffect("HoldNegativeGrid");
        animations.addPageEffect("PrepPostSessionEnter");

        await delay(600 * speedMultiplier);
        animations.removePageEffect("HoldNegativeGrid");

        await delay(600 * speedMultiplier);
        const newProgressPlaceholder = {
            id: newCompletedThought.id,
            previousExploreCount: startingExploreCount,
            isBrandNew: isNewThoughtFlow
        }
        setProgressPlaceholder(newProgressPlaceholder);
        setProgressRows(labeledProgressRows);
        animations.removePageEffect("FadeOutProgressGrid");
        console.log('qwe setProgressPlaceholder', newProgressPlaceholder, isNewThoughtFlow);

        await delay(800 * speedMultiplier);
        user.showResponsePop1 = false;
        user.progress_grid = labeledProgressRows;
        user.animatingCompletedThought = { ...newCompletedThought };
        user.animatingCompletedThought.previousExploreCount = previousExploreCount;
        console.log('qwe changing user to trigger progress animation', user, newCompletedThought);
        changeUser({ ...user });

        // Clean up.
        setShowCompletedSlotMessage(false);
        setCompletedSlotMessage(null);

        await delay(1400 * speedMultiplier);

        setProgressPlaceholder(null);

        // Reset our state so the user can start their next thought correctly.
        setOptions([]);
        setNextOptions([]);
        setNextStageOptions([]);
        nextOptionsRef.current = [];
        setIsLoading(true);
        setThought(null);
        setResponses([]);
        setTransitionItems([]);
        setAsideResponseStage(null);
        setAsideResponses([]);
        setStrikingLineNumber(0);
        setStrikeWidths([0, 0, 0]);
        setIsAnimatingCompletedThought(false);
        setResponseList1([]);
        setResponseList2([]);

        // Behind the scenes, prepare our Completed Message content to fade in down below.
        setShowCompletedSlotMessage(false);
        setCompletedSlotMessage({
            number: completedSlot.number,
            video: null,
            color: completedSlot.thought.row.color,
            label: getRotatingCheckLabel(completedSlot.number)
        });

        // Check if the user just completed their session.
        //const isSessionCompleted = !sessionThoughts.find(t => t.status === "Pending");
        const isSessionCompleted = false;

        // Clean up this page effect. Not sure best place for this yet.
        animations.removePageEffect("ShowMySessionTitle");

        // Slight delay before we animate Completed Message.
        await delay(1000 * speedMultiplier);

        // Fade in our Completed Message.
        console.log('TRR Show Completed Message');
        setShowCompletedSlotMessage(true);
        await delay(1000 * speedMultiplier);

        // Fade out our Completed Message.
        setShowCompletedSlotMessage(false);
        await delay(200 * speedMultiplier);

        setPageEffects([]);
        animations.removePageEffect("PrepPostSessionEnter"); // delay was: 1850 + 3600

        console.log('manage onboarding pops', user);
        if (user.popRound === 2) {
            console.log('manage onboarding pops - set to round 3', user);
            user.popRound = 3;
            changeUser({ ...user });
        }

        console.log('qwe check popRound 1 to 2', user, user.isNew && user.popRound === 1);

        // Manage onboarding pop-ups. Blah.
        if (user.isNew && user.popRound === 1) {
            console.log('qwe manage onboarding pops - set to round 2', user);
            user.popRound = 2;
            changeUser({ ...user });
        }

        await delay(500);

        // Clean up.
        setShowCompletedSlotMessage(false);
        setCompletedSlotMessage(null);
        const slideEl = document.querySelector('.SlideIn');
        if (slideEl) {
            slideEl.classList.remove("SlideIn");
        }

        for (var i = 0; i < 5; i++) {
            let previousSlot = sessionThoughts.find(t => t.slide_in === true);
            let previousSlotIndex = sessionThoughts.findIndex(t => t.slide_in === true);
            if (previousSlot) {
                previousSlot.slide_in = false;
                //sessionThoughts[previousSlotIndex] = { ...previousSlot };
                let newSessionThoughts2 = [...sessionThoughts];
                newSessionThoughts2[previousSlotIndex] = { ...previousSlot };
                //setSessionThoughts(newSessionThoughts2);
                setSessionThoughts([...newSessionThoughts2]);
            }
            const slideEl = document.querySelector('.SlideIn');
            if (slideEl) {
                slideEl.classList.remove("SlideIn");
            }
        }

        console.log('session state at end of thoughtCompleted',);

        animations.removePageEffect("SlideOutNotebook");
        animations.removePageEffect("FadeOutResponseSheet");
        animations.removePageEffect("FadeOutAsideSheet");
        animations.removePageEffect("SlideOutStickyNote");

        // NOTE: DO NOT GATE YET
        // if (user.is_temporary) {
        //     const thoughtCount = sessionThoughts.filter(item => item.status === "Completed");

        //     if (thoughtCount >= 3) {
        //         user.CreateAccountDrawer = true;
        //         user.CreateAccountForceRegistration = true;
        //         changeUser({ ...user });
        //     }
        // }

        return;


        // setTimeout(() => {
        //     console.log('removeHold check', !isSessionCompleted);
        //     if (!isSessionCompleted) {
        //         animations.removePageEffect("HoldNegativeGrid");
        //     }
        // }, completedMessageDelay + 0 + delayAdd);

        if (!isSessionCompleted) {
            setTimeout(() => {
                setPageEffects([]);
                animations.removePageEffect("PrepPostSessionEnter");
            }, 1850 + 3600); // Was 3600 with completedMessages
        }

        setTimeout(() => {
            const slideEl = document.querySelector('.SlideIn');
            if (slideEl) {
                slideEl.classList.remove("SlideIn");
            }
        }, 700);



        // This animation effect we don't want anymore.
        // setTimeout(() => {
        //     const slideEl = document.querySelector('.SlideInNumber');
        //     if (slideEl) {
        //         slideEl.classList.remove("SlideInNumber");
        //     }
        // }, isReinforce ? 1400 : 100);

        // Backup retry for race conditions?
        setTimeout(() => {
            const slideEl = document.querySelector('.SlideIn');
            if (slideEl) {
                slideEl.classList.remove("SlideIn");
            }
        }, 1000);
    };

    const handleAssessmentsBadgeClick = () => {
        user.PickGoalsDrawer = "Reassess";
        changeUser({ ...user });
    };

    const handleStartingAssessmentsCompleted = () => {
        user.isStartingFirstThought = true;
        changeUser({ ...user });
    };

    const copySessionCardToExpand = () => {
        const el = document.querySelector('.SessionThoughtActive');
        const destEl = document.querySelector('.drop-dest');

        const elRect = el.getBoundingClientRect();
        const destinationRect = destEl.getBoundingClientRect();

        const newEl = el.cloneNode(true);
        newEl.id = newEl.id + '-clone';
        newEl.style.position = "fixed";
        newEl.style.top = elRect.top - 5.0 + 10 + 'px';
        newEl.style.left = destinationRect.left + 0 + 14 + 6 + 'px';
        newEl.style.width = "360px";

        newEl.style.boxSizing = "border-box";
        newEl.querySelector('.drop-card').style.boxShadow = "none";
        newEl.style.background = "transparent";
        newEl.style.backgroundColor = "transparent";

        newEl.style.transition = ".4s";

        document.querySelector('.ThoughtGrid2').appendChild(newEl);

        return newEl;
    };

    const expandSessionCard = (newEl, isReinforce) => {
        const el = document.querySelector('.SessionThoughtActive');
        const destEl = document.querySelector('.drop-dest');

        if (!newEl || isReinforce) {
            return;
        }

        const elRect = el.getBoundingClientRect();
        const destinationRect = destEl.getBoundingClientRect();

        setTimeout(() => {
            newEl.style.transform = "scale(1.1)";
            newEl.querySelector('.drop-card').style.boxShadow = "1px 2px 3px 1px #e3e6f7";
        }, 10);

        setTimeout(() => {
            newEl.style.top = destinationRect.top - 25 + 'px';
            newEl.style.left = destinationRect.left + 0 + 'px';
            newEl.style.width = "373px";
            newEl.style.boxSizing = "border-box";
            //newEl.querySelector('.drop-card').style.boxShadow = null;


        }, 300);

        setTimeout(() => {

            newEl.style.transform = "scale(1.0)";
            newEl.querySelector('.drop-card').style.boxShadow = null;
            //newEl.querySelector('.drop-card').style.boxShadow = "1px 2px 3px 1px #fbfaf1";
            newEl.querySelector('.drop-card').classList.add('Landing');
            //newEl.querySelector('.drop-card').classList.add('Landed');
        }, 875);

        setTimeout(() => {
            newEl.querySelector('.drop-card').classList.add('Landed');
        }, 1125);

        setTimeout(() => {
            newEl.remove();
        }, 1150);
    };

    const handleAssessmentCompleted = () => {
        setViewName("AssessmentsPeek");
    };

    const handleTurnInClick = () => {
        setViewName("TurnInStack");
    };

    const handleCreateAccountClick = () => {
        user.CreateAccountDrawer = true;
        //user.CreateAccountForceRegistration = forceRegistration;
        changeUser({ ...user });
    };

    const handleAccountBubbleClick = () => {
        user.CreateAccountDrawer = true;

        //user.CreateAccountForceRegistration = forceRegistration;
        changeUser({ ...user });
    };

    const handleCoachCardClick = () => {

        window.Intercom('show');
    };

    const handleResponseStageCompleted = () => {
        Back.recordEvent("CompletedResponses");

        console.log('handleResponseStageCompleted', transitionItems, exploreItems);

        handleExploreCompleted();

        return;

        const nextStage = getNextStage(responseStage);

        setAsideResponseStage(responseStage);
        setAsideResponses([...transitionItems]);

        if (nextStage === "Notes") {
            user.completed_thoughts_count++;
            changeUser({ ...user });

            setTimeout(() => {
                animations.addPageEffect("HideResponseGrid");
            }, 300);

            setTimeout(() => {

                setViewName("ExploreSheet");
                setTimeout(() => {
                    animations.addPageEffect("FadeInExploreSheet");
                }, 100);

            }, 800);

            setTimeout(() => {
                setShowCompletedSlotMessage(false);
            }, 1500);

            setTimeout(() => {
                animations.removePageEffect("HideResponseGrid"); // Clean up.
            }, 3400);

            return;
        }

        setResponseStage(nextStage);
        thought.responseStage = nextStage;

        setResponses([]);
        setTransitionItems([]);

        // DEBT: ALL OF THIS CAN GO I THINK
        setTimeout(async () => {

            if (nextStageOptions && nextStageOptions.length > 0) {
                setIsLoading(true);
                await delay(500);
                setOptions(nextStageOptions);
                setNextStageOptions([]);
                setIsLoading(false);
                setIsFakeLoading(false);
                setIsNextFresh(false);
                isNextFreshRef.current = false;
                loadNextOptions(thought, nextStage);
            } else {
                //handleResponseRefreshRequested();
                setIsNextFresh(false);
                isNextFreshRef.current = false;
                loadNextOptions(thought, nextStage);

                setTimeout(() => {
                    handleResponseRefreshRequested(false, nextStage);
                }, 300);
            }
        }, 10);





    };

    const handleResponseAdded = async (option, index, isStrongPress) => {

        await delay(300);

        const strikesExpected = 40;

        responses.push(1);
        setResponses(responses);

        // Here we load and get the video message in position to transition in
        // once the user finishes their Evidence Sheet.
        if (responses.length === 1) {
            const rotatingContentNumber = getRotatingCompletionNumber(user.completed_thoughts_count + 1);
            setCompletedSlotMessage({
                number: 4,
                video: getRotatingCompletionVideo(rotatingContentNumber),
                label: getRotatingCompletionLabel(rotatingContentNumber)
            });
        }

        if (responses.length >= strikesExpected) {
            setTimeout(() => {
                handleResponseStageCompleted();
            }, 500);
            //setShowResponsesDoneButton(true);



            // user.completed_thoughts_count++;
            // changeUser({ ...user });

            // setTimeout(() => {
            //     var videoEl = document.querySelector('.completed-video');
            //     if (videoEl) {
            //         console.log('video currentTime set');
            //         videoEl.currentTime = 0.9;
            //     }
            //     setShowCompletedSlotMessage(true);
            // }, 50);

            // setTimeout(() => {
            //     animations.addPageEffect("HideResponseGrid");
            // }, 300);

            // setTimeout(() => {

            //     if (thought.type === "Reinforce" || thought.status === "Reinforcing") {
            //         thought.type = "Reinforce";
            //         thought.status = "Reinforcing";
            //     }

            //     setViewName("ExploreSheet");

            //     setTimeout(() => {
            //         animations.addPageEffect("FadeInExploreSheet");
            //     }, 100);

            // }, 800);

            // setTimeout(() => {
            //     setShowCompletedSlotMessage(false);
            // }, 1500);

            // setTimeout(() => {
            //     animations.removePageEffect("HideResponseGrid"); // Clean up.
            // }, 3400);
        }

        let areaName = UI.extractWordInBrackets(option);
        let sentence = UI.removeBracketedWord(option);

        const areaOptions = ["Mood", "Motivation", "Focus", "Ease", "Purpose", "Openness"];
        if (areaName === "Possibility") {
            areaName = "Openness";
        }
        if (!areaOptions.includes(areaName)) {
            areaName = "Ease";
        }

        const transitionItem = {
            option: option,
            sentence: sentence,
            area: areaName,
            isStrongPress: isStrongPress,
            isFaded: true,
            isShown: false,
            number: responses.length
        };

        setTransitionItems(
            (prev) => {
                return [...prev, transitionItem];
            }
        );
        setExploreItems(
            (prev) => {
                return [...prev, transitionItem];
            }
        );
        // setAsideResponses(
        //     (prev) => {
        //         return [...prev, transitionItem];
        //     }
        // );

        // TESTING: ALWAYS ADD TO LEFT "TRUE"
        if (responseList1.length < 20) {
            setResponseList1(
                (prev) => {
                    return [...prev, transitionItem];
                }
            );
        } else {
            setResponseList2(
                (prev) => {
                    return [...prev, transitionItem];
                }
            );
        }

        setTimeout(() => {
            // const newResponseList1 = responseList1.map((item, i) => {
            //     item.isFaded = false;
            //     return item;
            // });
            // setResponseList1(newResponseList1);
            const newTransitionItem = { ...transitionItem };
            newTransitionItem.isFaded = false;
            newTransitionItem.isShown = true;

            setResponseList1(
                (prev) => {
                    const newResponseList1 = prev.map((item, i) => {
                        item.isFaded = false;
                        item.isShown = true;
                        return item;
                    });
                    return [...newResponseList1];
                }
            );
        }, 700);

        // DEBT: NOT TOTALLY SURE IF ANY OF THIS STILL NEEDED? 2/3
        setTimeout(() => {
            // const newResponseList1 = responseList1.map((item, i) => {
            //     item.isFaded = false;
            //     return item;
            // });
            // setResponseList1(newResponseList1);
            const newTransitionItem = { ...transitionItem };
            newTransitionItem.isFaded = false;
            newTransitionItem.isShown = true;

            setResponseList2(
                (prev) => {
                    const newResponseList2 = prev.map((item, i) => {
                        item.isFaded = false;
                        item.isShown = true;
                        return item;
                    });
                    return [...newResponseList2];
                }
            );
        }, 800);

        console.log('response lists', responseList1, responseList2);

        const progressCount = responses.length + startingExploreCount;
        console.log('qwe progressCount', progressCount, startingExploreCount);
        const progressEl = document.querySelector('.SessionThoughtExpanded .ProgressCounter .number-current');

        progressEl.innerHTML = progressCount;

        if (!user.daily_goal_count) {
            user.daily_goal_count = 6;
        }

        user.daily_goal_count++;
        changeUser({ ...user });

        return;
        //setIsLoading(true);

        setIsNextLoading(true);
        setNextOptions([]);
        nextOptionsRef.current = [];
        isExpandLoading.current = true;

        const expandThought = UI.removeBracketedWord(option);

        var attempts = 0;
        while (true) {
            attempts = attempts + 1;
            console.log('VOO start loop', attempts);

            if (attempts > 5) {
                break;
            }

            console.log('exploreItemsHere', transitionItems);

            const response = await Back.expandOnThought(expandThought, thought.thought, transitionItems);

            if (!response) {
                continue;
            }

            const items = response.items;

            console.log('VOO nextOptions return voice', response.voice, responseVoiceRef.current);

            if (items && Array.isArray(items) && items.length > 0) {
                setNextOptions(items);
                nextOptionsRef.current = items;
                break;
            }
        }

        setIsNextLoading(false);
        setIsNextFresh(true);
        isNextFreshRef.current = true;
        isExpandLoading.current = false;
    };

    const drawStrikeSegment = (strikesExpected) => {

        // Fancy JS to calculate the pixel length of each line of our Negative thought.
        // Unoptimized to call it everytime here though.
        const lineWidths = calcLines();

        // Messy way to initialize our strike widths array 
        // to have one 0 value for each line we know we WILL be striking in the future.
        // Really, this should be happening elsewhere.
        let initStrikeWidths = [];
        if (!strikeWidths) {
            for (var i = 0; i < lineWidths.length; i++) {
                initStrikeWidths.push(0);
            }
        } else {
            initStrikeWidths = strikeWidths;
        }

        const totalWidth = lineWidths.reduce((s, a) => s + a, 0);
        const segWidth = totalWidth / strikesExpected;

        const currentLineWidth = lineWidths[strikingLineNumber];
        const currentStrikeWidth = initStrikeWidths[strikingLineNumber];

        let newStrikeWidth = currentStrikeWidth + segWidth;
        let remainingStrikeWidth = 0;

        // Check if there will be REMAINING strike to consider.
        // Meaning, any time we reach the end of the line, we'll be striking on TWO LINES -- 
        // the current line to finish striking it out, and then a little bit on the next line too.
        if (newStrikeWidth > currentLineWidth) {
            remainingStrikeWidth = newStrikeWidth - currentLineWidth;
            newStrikeWidth = currentStrikeWidth;
        }

        const newStrikeWidths = [...initStrikeWidths];

        // If there was any remaining strike, that means 
        // we're FINISHING our strike for the current line we were striking,
        // and then adding the FIRST BIT of strike for the next line we've moving onto.
        if (remainingStrikeWidth) {
            newStrikeWidths[strikingLineNumber] = currentLineWidth;
            newStrikeWidths[strikingLineNumber + 1] = remainingStrikeWidth;
        } else {
            newStrikeWidths[strikingLineNumber] = newStrikeWidth;
        }

        console.log('dds setStrikeWidths', newStrikeWidths);

        // Save our new strike widths for each line.
        // This will update the UI strike lines for us.
        setStrikeWidths(newStrikeWidths);

        // If we had a remaining strike at all, 
        // that means we've moved to the next line to strike for next time.
        if (remainingStrikeWidth) {
            setStrikingLineNumber(strikingLineNumber + 1);
        }
    };

    const handleResponseVoiceChanged = (voice) => {
        Back.recordEvent("ClickedVoiceChange", voice);

        setResponseVoice(voice);
        responseVoiceRef.current = voice;

        handleResponseRefreshRequested(false, voice);
    };

    const handleExploreFullyCompleted = async (newExploreItems) => {

        loadReframingOptions();

        await delay(200);

        animations.addPageEffect("SlideMyNotesToSideStart");

        await delay(400);

        animations.addPageEffect("SlideMyNotesToSide");

        await delay(600);

        animations.removePageEffect("FadeOutExploreCompletedMessage");
        animations.addPageEffect("SlideMyNotesToSide2");

        await delay(800);

        animations.addPageEffect("SlideInPostThought");

        //setViewName("ThoughtPostRating");

        // Clean up.
        setTimeout(() => {
            //animations.removePageEffect("SlideAwayMyNotes");
        }, 1000);
    };

    const handleExploreCompleted = async (newExploreItems, newReframingThought) => {

        console.log('handleExploreCompleted explore', thought, newExploreItems);
        if (!newExploreItems) {
            newExploreItems = exploreItems;
            console.log('handleExploreCompleted replace', exploreItems);
        }
        console.log('handleExploreCompleted explore2', newExploreItems);

        let isNewReframing = false;
        let reframingThought = thought.reframing_thought;

        if (newReframingThought) {
            isNewReframing = true;
            reframingThought = newReframingThought;
        }
        if (!thought.isReinforce && !thought.reframing_thought) {
            reframingThought = "Empty";
        }

        const newThought = { ...thought };
        newThought.slam_id = slam.id;
        newThought.pre_rating = 0;
        newThought.rating = 0;
        newThought.reframing_thought = reframingThought;
        newThought.isNewReframing = isNewReframing;
        newThought.reframing_rating = 0;
        newThought.matching_thought_set_id = null;
        newThought.is_neutral = thought.isNeutral;
        newThought.progress = thought.progress;
        newThought.exploreItems = newExploreItems;
        newThought.isReinforce = thought.isReinforce;
        newThought.isReinforcing = thought.isReinforcing;


        // DEBT: Forcing "category" to always be "Air" even though I have no idea if this is used still.
        //       did this because we had some "AirCache" values.
        newThought.category = "Air";

        if (isNewReframing) {
            newThought.area = UI.extractWordInBrackets(newReframingThought);
            newThought.progress = 0;
        }

        if (thought.thought_set_id) {
            newThought.thought_set_id = thought.thought_set_id;
        }
        else if (thought.thought_set && thought.thought_set.id) {
            newThought.thought_set_id = thought.thought_set.id;
        }

        if (!newThought.progress) {
            newThought.progress = 0;
        }

        console.log('handleExploreCompleted', newThought);

        let bonusAmount = 0;
        if (isNewReframing || newThought.isReinforce) {
            bonusAmount = 20;
        } else {
            bonusAmount = 34;
        }

        newThought.original_progress = newThought.progress;
        newThought.new_progress = newThought.progress + bonusAmount;
        if (newThought.new_progress > 100) {
            newThought.new_progress = 100;
        }

        animations.removePageEffect("FadeInProgressGrid");
        animations.addPageEffect("FadeOutResponseGrid");

        const startTime = Date.now();
        const response = await Back.recordCompletedThought(newThought);
        const endTime = Date.now();
        const elapsedTime = endTime - startTime;
        const newUser = { ...response.user };
        const newProgressGrid = { ...response.progress_grid };

        console.log('wer completed response', newUser, newProgressGrid);

        newUser.progress_grid = newProgressGrid;

        const newQuestion = response.new_question;

        let delayTime = 400 - elapsedTime;
        if (delayTime > 0) {
            await delay(delayTime);
        }


        //await delay(400);

        // Slide up Sticky Note.
        animations.addPageEffect("SlideOutStickyNote");

        const stickyNoteEl = document.querySelector('.SessionThoughtExpanded');
        stickyNoteEl.classList.add("SlideOutStickyNote");


        //return;


        const currentSessionSlotNumber = sessionThoughts.find(i => i.status === "Active") ? sessionThoughts.find(i => i.status === "Active").number : 1;
        const completedSlot = {
            id: currentSessionSlotNumber, number: currentSessionSlotNumber, status: "Completed", thought: { ...newThought }, primary_skill: newThought.primary_skill, slide_in: true
        };

        const newSessionThoughts = [...sessionThoughts];
        const replacementIndex = sessionThoughts.findIndex(s => s.number === completedSlot.number);
        newSessionThoughts[replacementIndex] = completedSlot;
        setSessionThoughts(newSessionThoughts);

        console.log('qwe setSessionThoughts', newSessionThoughts, completedSlot);

        await delay(400);

        handleThoughtCompleted(completedSlot, newUser, response.completed_thought_set_id);
    };

    const handleExploreResponseClick = (item) => {
        console.log('handleExploreResponseClick', item);
        if (viewName !== "ExploreSheet") {
            return;
        }

        const newItem = {
            number: exploreItems.length + 1,
            isNew: true,
            isFading: true,
            label: item.sentence
        };

        // Check if item already is in the items to prevent duplicates.

        const newExploreItems = [...exploreItems, ...[newItem]];

        setExploreItems(newExploreItems);

        setTimeout(() => {
            const refreshedExploreItems = newExploreItems.map(item => ({ ...item, isFading: false }));
            setExploreItems(refreshedExploreItems);
        }, 100);

        const matchingResponse = transitionItems.find(i => i.sentence === item.sentence);
        const matchingResponseIndex = transitionItems.findIndex(i => i.sentence === item.sentence);

        matchingResponse.isStrongPress = true;
        const newTransitionItems = [...transitionItems];
        newTransitionItems[matchingResponseIndex] = matchingResponse;

        setTransitionItems(newTransitionItems);

        if (newExploreItems.length === 6) {
            handleExploreFullyCompleted(newExploreItems);
        }

        else if (newExploreItems.filter(item => item.isNew === true).length >= 2) {
            handleExploreCompleted(newExploreItems);
        }
    };

    const handleResponseStepCompleted = () => {

    };

    const handleResponseRefreshRequested = (isRetry, stage) => {

        if (!stage) {
            stage = responseStage;
        }

        setIsLoading(true);

        // if (isExpandLoading.current === true) {
        //     setTimeout(
        //         () => {
        //             handleResponseRefreshRequested(true, stage);
        //         }, 100
        //     );
        //     return;
        // }


        // if (isNextFreshRef.current === true &&
        //     nextOptionsVoiceRef.current !== responseVoiceRef.current) {

        //     if (!isNextLoading) {
        //         isNextFreshRef.current = false;
        //         setIsNextLoading(true);
        //         loadNextOptions(thought);

        //         setTimeout(() => {
        //             handleResponseRefreshRequested(true);
        //         }, 100);
        //     }

        //     return;
        // }

        if (isNextFreshRef.current === true) {
            console.log('VOO branch 1');

            setTimeout(() => {
                setIsFakeLoading(true);
                setOptions(nextOptionsRef.current);
                setIsLoading(false);
                setIsFakeLoading(false);
                setIsNextFresh(false);
                isNextFreshRef.current = false;
                loadNextOptions(thought, stage);

                [].forEach.call(document.querySelector('.ResponseGrid').querySelectorAll('.Selected'), function (el) {
                    el.classList.remove('Selected');
                });

                Back.recordEvent("FinishedResponsesRefresh");

            }, isRetry ? 400 : 400);
            return;
        }

        setTimeout(
            () => {
                handleResponseRefreshRequested(true, stage);
            }, 100
        )
        return;
    };

    const handleStartAssessment = (assessmentRound) => {
        setViewName("TakeAssessment");
        setActiveAssessment(assessmentRound);
    };

    const handleResponsesDoneClick = () => {

    };

    const fadeInThoughtLogCard = () => {
        const cardEl = document.querySelector('.ThoughtLogCard.Selected');

        cardEl.classList.add('FadedOut');

        setTimeout(() => {
            cardEl.classList.remove('Selected');
            cardEl.classList.remove('FadedOut');
            //cardEl.classList.add('FadingIn');
        }, 500);
    };



    // TEST CASES TESTING
    useEffect(() => {

        // setShowCompletedSlotMessage(false);
        // setCompletedSlotMessage({
        //     number: 3,
        //     video: null,
        //     color: "Purple",
        //     label: getRotatingCheckLabel(3)
        // });

        // setTimeout(() => {
        //     console.log('run test');
        //     setShowCompletedSlotMessage(true);
        // }, 3500);

        // setTimeout(() => {
        //     console.log('run test');
        //     setShowCompletedSlotMessage(true);
        // }, 7500);

        // setShowCompletedSlotMessage(true);

        // setTimeout(() => {
        //     fadeInThoughtLogCard();
        // }, 1000);

        const testCase = "ExploreSheet222";

        // Test Case: New Thought at the ExploreSheet.
        const randomId = getRandomIntegerBetween(100000, 999999);

        if (testCase === "ExploreSheet") {

            // UNCOMMENT THIS TO TESTS FINISHING ALL 6 NOTES
            //const testExploreItems = [{ "isNew": false, "label": "Transformation begins when I see possibility", "number": 1, "isFading": false }, { "isNew": false, "label": "Growth often starts with openness", "number": 2, "isFading": false },
            //{ "isNew": false, "label": "Transformation begins when I see possibility", "number": 3, "isFading": false }, { "isNew": false, "label": "Transformation begins when I see possibility", "number": 4, "isFading": false }];
            const testExploreItems = [{ "isNew": false, "label": "Transformation begins when I see possibility", "number": 1, "isFading": false }, { "isNew": false, "label": "Growth often starts with openness", "number": 2, "isFading": false }];

            const testThought = {
                id: null,
                thought: "This is a test " + randomId,
                sentence: "This is a test " + randomId,
                type: "Negative",
                category: "Work",
                status: "Exploring",
                isNeutral: true,
                isNegative: true,
                isReinforce: false,
                isReinforcing: false,
                progress: 0,
                exploreItems: testExploreItems
            };
            const testSlots = [
                {
                    id: 1,
                    number: 1,
                    status: "Active",
                    thought: { ...testThought }
                },
                {
                    id: 2,
                    number: 2,
                    status: "Pending",
                },
                {
                    id: 3,
                    number: 3,
                    status: "Pending",
                }
            ];
            const testResponses = [
                {
                    option: "This is response 1",
                    sentence: "This is response 1",
                    area: "Ease",
                    isStrongPress: false,
                    number: 1
                },
                {
                    option: "This is response 2",
                    sentence: "This is response 2",
                    area: "Ease",
                    isStrongPress: false,
                    number: 2
                },
                {
                    option: "This is response 3",
                    sentence: "This is response 3",
                    area: "Ease",
                    isStrongPress: false,
                    number: 3
                },
                {
                    option: "This is response 4",
                    sentence: "This is response 4",
                    area: "Ease",
                    isStrongPress: false,
                    number: 4
                },
                {
                    option: "This is response 5",
                    sentence: "This is response 5",
                    area: "Ease",
                    isStrongPress: false,
                    number: 5
                }
            ];
            setViewName("ExploreSheet");
            setThought(testThought);
            setTransitionItems(testResponses);
            setSessionThoughts(testSlots);
            setExploreItems(testExploreItems);
            setTimeout(() => {
                animations.addPageEffect("FadeInExploreSheet")
                // loadOptions(testThought);
                // loadNextOptions(testThought);
            }, 200);

            loadReframingOptions(testThought);
        }


        setTimeout(() => {

            setShowCompletedSlotMessage(false);
            setCompletedSlotMessage({
                number: 3,
                video: null,
                color: 'Teal',
                label: getRotatingCheckLabel(3)
            });

            const rotatingContentNumber = getRotatingCompletionNumber(user.completed_thoughts_count);
            // setCompletedSlotMessage({
            //     number: 4,
            //     video: getRotatingCompletionVideo(rotatingContentNumber),
            //     label: getRotatingCompletionLabel(rotatingContentNumber)
            // });

            // setShowCompletedSlotMessage(true);
        }, 500);

        // setTimeout(() => {
        //     setShowCompletedSlotMessage(true);
        // }, 950);

        // setTimeout(() => {
        //     setShowCompletedSlotMessage(false);
        // }, 3950);


        // setViewName("ThoughtPostRating");
        // setThought({
        //     type: "Reinforce",
        //     thought: "I'm not smart enough to be an entrepreneur",
        //     slam_id: 1,
        //     reframing_thought: "I can try my best to be an entrepreneur",
        //     pre_rating: 80,
        //     rating: 50,
        //     reframing_rating: 60,
        //     category: "Work"
        // });

        // const t = { "thought": "I'll never finish on time", "type": "Negative", "ratings": [], "reframing_ratings": [], "category": "Work" };
        // setSessionThoughts(
        //     [{ "id": 1, "number": 1, "status": "Active", "thought": { "thought": "I'll never finish on time", "type": "Negative", "ratings": [], "reframing_ratings": [], "category": "Work" }, "reps": [{ "status": "Pending" }, { "status": "Pending" }, { "status": "Pending" }] }, { "id": 2, "number": 2, "status": "Pending" }, { "id": 3, "number": 3, "status": "Pending" }]
        // );
        // setThought(
        //     t
        // );
        // setViewName("ResponseGrid");

        // loadOptions(t);

        // setViewName("ResponseGrid");
        // setThought({
        //     type: "Negative",
        //     isNeutral: true,
        //     thought: "I'm not smart enough to be an entrepreneur",
        //     slam_id: 1,
        //     reframing_thought: "I can try my best to be an entrepreneur",
        //     pre_rating: 80,
        //     rating: 50,
        //     reframing_rating: 60,
        //     category: "Work"
        // });
        // setTimeout(() => {
        //     loadOptions("I'm not smart enough to be an entrepreneur");
        // }, 500);


    }, []);

    // 22, 12, 8, 11, 5

    const onAnimatingCompletedThought = (slot) => {

        if (slot && slot.thought.type === "Reinforce") {
            setViewName("NegativeGrid");
            //setPageEffects(["FadeOutSessionGrid"]);
            animations.addPageEffect("FadeOutSessionGrid");
            setTimeout(() => {
                //setPageEffects(["FadeInSessionGrid"]);
            }, 1000);
            return;
        }

        setIsAnimatingCompletedThought(true);

        setTimeout(() => {
            document.querySelector('.AnimatedFloat').classList.add('AnimatedFloatFade');
        }, 100);
    };


    // RESPONSE LOADING
    const loadOptions = async (loadThought) => {
        var group = 0;
        var attempts = 0;

        let theseResponses = [];

        while (true) {
            attempts = attempts + 1;

            if (attempts > 5) {
                //setOptions([]);
                break;
            }

            let exploreItems = [];
            if (loadThought.exploreItems) {
                exploreItems = loadThought.exploreItems;
            } else if (loadThought.context && loadThought.context.exploreItems) {
                exploreItems = loadThought.context.exploreItems;
            }

            console.log('loadThought', loadThought, exploreItems);

            //const requestedCount = (group === 1 || group === 2) ? 4 : 2;
            let response = null;
            if (loadThought.type === "Reinforce" || loadThought.isReinforce) {
                response = await Back.respondToReinforcementThought(loadThought.reframing_thought, exploreItems, options, group, 8, isReframingType, responseVoice);
            } else {
                console.log('respondToThought1', loadThought.responseStage);
                response = await Back.respondToThought(loadThought.thought, exploreItems, options, group, 8, isReframingType, responseVoice, transitionItems, loadThought.purpose, loadThought.row, loadThought.responseStage);
            }

            if (!response) {
                console.log('loadOptions', 'bad response');
                continue;
            }

            const items = response.items;

            console.log('VOO options return voice', response.voice);

            if (items && Array.isArray(items) && items.length > 0) {

                for (var i in items) {
                    if (typeof items[i] !== "string") {
                        console.log('Non string item', items[i]);
                        continue;
                    }
                }

                var highlightIndex = Math.floor(Math.random() * items.length);
                var highlightItem = items[highlightIndex];

                //setHighlightedOption(highlightItem);

                console.log('loadOptions', 'setOptions');

                theseResponses = items;


                console.log('VOO displaying for voice', response.voice);
                setOptions(prev => [...prev, ...items]);
                if (previousOptionsRef.current.length < 40) {
                    previousOptionsRef.current = [...previousOptionsRef.current, ...items];
                }
                break;
            } else {
                console.log('loadOptions Bad response', response, items);
            }
        }

        setIsLoading(false);

        loadNextOptions(loadThought);
        loadNextStageOptions(loadThought);
    };

    const loadNextOptions = async (loadThought, stage) => {

        if (!previousOptionsRef.current) {

        }

        if (!stage) {
            stage = responseStage;
        }

        let ignoreItems = false;

        setIsNextLoading(true);
        var attempts = 0;
        while (true) {
            attempts = attempts + 1;
            console.log('VOO start loop', attempts);

            if (attempts > 5) {
                setNextOptions([]);
                ignoreItems = true;
                console.log('VOO break from 5 attempts');
                break;
            }

            let exploreItems = [];
            if (loadThought.exploreItems) {
                exploreItems = loadThought.exploreItems;
            } else if (loadThought.context && loadThought.context.exploreItems) {
                exploreItems = loadThought.context.exploreItems;
            }

            let response = null;
            if (loadThought.type === "Reinforce" || loadThought.isReinforce) {
                response = await Back.respondToReinforcementThought(loadThought.reframing_thought, exploreItems, previousOptionsRef.current, 0, 8, isReframingType, responseVoiceRef.current);
            } else {
                console.log('respondToThought2', loadThought.responseStage);
                response = await Back.respondToThought(loadThought.thought, exploreItems, previousOptionsRef.current, 0, 8, isReframingType, responseVoiceRef.current, transitionItems, loadThought.purpose, loadThought.row, loadThought.responseStage);
            }

            if (!response) {
                continue;
            }

            const items = response.items;

            if (response.voice !== responseVoiceRef.current) {
                console.log('VOO voice mismatch');
                setNextOptions([]);
                ignoreItems = true;
                break;
            }

            if (items && Array.isArray(items) && items.length > 0) {
                console.log('VOO voice matching, taking items');

                if (previousOptionsRef.current.length < 40) {
                    previousOptionsRef.current = [...previousOptionsRef.current, ...items];
                }
                setNextOptions(items);
                nextOptionsRef.current = items;
                nextOptionsVoiceRef.current = response.voice;
                break;
            }
        }

        if (ignoreItems) {
            loadNextOptions(loadThought);
            return;
        }

        setIsNextLoading(false);
        setIsNextFresh(true);
        isNextFreshRef.current = true;
    };

    const loadNextStageOptions = async (loadThought) => {
        var group = 0;
        var attempts = 0;

        let theseResponses = [];

        while (true) {
            attempts = attempts + 1;

            if (attempts > 5) {
                //setOptions([]);
                break;
            }

            let exploreItems = [];
            if (loadThought.exploreItems) {
                exploreItems = loadThought.exploreItems;
            } else if (loadThought.context && loadThought.context.exploreItems) {
                exploreItems = loadThought.context.exploreItems;
            }

            console.log('loadThought', loadThought, exploreItems);

            const nextStage = getNextStage(loadThought.responseStage);

            //const requestedCount = (group === 1 || group === 2) ? 4 : 2;
            let response = null;
            if (loadThought.type === "Reinforce" || loadThought.isReinforce) {
                response = await Back.respondToReinforcementThought(loadThought.reframing_thought, exploreItems, options, group, 8, isReframingType, responseVoice);
            } else {
                console.log('respondToThought1', loadThought.responseStage);
                response = await Back.respondToThought(loadThought.thought, exploreItems, options, group, 8, isReframingType, responseVoice, transitionItems, loadThought.purpose, loadThought.row, nextStage);
            }

            if (!response) {
                console.log('loadOptions', 'bad response');
                continue;
            }

            const items = response.items;

            console.log('VOO options return voice', response.voice);

            if (items && Array.isArray(items) && items.length > 0) {

                for (var i in items) {
                    if (typeof items[i] !== "string") {
                        console.log('Non string item', items[i]);
                        continue;
                    }
                }

                var highlightIndex = Math.floor(Math.random() * items.length);
                var highlightItem = items[highlightIndex];

                //setHighlightedOption(highlightItem);

                console.log('loadOptions', 'setOptions');

                theseResponses = items;

                console.log('wer setNextStageOptions', items);
                setNextStageOptions(items);

                break;
            } else {
                console.log('loadOptions Bad response', response, items);
            }
        }
    };
    // EO RESPONSE LOADING

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Reframing Preloading

    const loadReframingOptions = async (providedThought, isRefresh) => {
        if (!providedThought) {
            providedThought = thought;
        }
        if (!isRefresh) {
            isRefresh = false;
        }

        var attempts = 0;
        while (true) {
            attempts = attempts + 1;

            if (attempts > 5) {
                setReframingOptions([]);
                console.log('setReframingOptions fail');
                break;
            }

            console.log('category check', providedThought);

            //const response = await Back.getReframingOptions(thought.thought);
            const response = await Back.getReframingOptions(providedThought.thought, providedThought.cateagory, isRefresh);

            if (!response) {
                continue;
            }

            const items = response.items;

            if (items) {

                // for (var i in items) {
                //     console.log('reframinglist typeof', typeof items[i]);
                //     if (typeof items[i] !== "object") {
                //         continue;
                //     }
                // }

                setReframingOptions(items);
                setIsReframingLoading(false);
                break;
            } else {
            }
        }
    };

    const handleReframingRefreshClick = async () => {
        Back.recordEvent("ClickedReframingRefresh");
        setIsReframingLoading(true);
        await loadReframingOptions(null, true);
        setIsReframingLoading(false);
        Back.recordEvent("FinishedReframingRefresh");
    };
    // EO REFRAMING PRELOADING

    const handleThoughtLogClick = (thought) => {
        //thought.type = "Reinforce";
        thought.isFromLog = true;

        handleThoughtClick(thought);
    };

    let mainContent = null;

    if (viewName === "NegativeGrid") {
        mainContent = <NegativeGrid sessionThoughts={sessionThoughts} onThoughtSelected={handleThoughtClick} onAboutClick={onAboutClick}></NegativeGrid>
        //mainContent = <RemindScreen></RemindScreen>
    }
    if (viewName === "ResponseGrid") {
        mainContent = <ResponseGrid thought={thought} options={options} responseStage={responseStage} nextOptions={nextOptions} isLoading={isLoading} voice={responseVoice} responses={responses} responseList1={responseList1} responseList2={responseList2} isNextLoading={isNextLoading} isNextFresh={isNextFresh} onResponseAdded={handleResponseAdded} onRefreshRequested={handleResponseRefreshRequested} onStageCompleted={handleResponseStageCompleted} onVoiceChanged={handleResponseVoiceChanged}></ResponseGrid>
    }

    // if (viewName === "StartMembership") {
    //     mainContent = <StartMembershipScreen slam={slam} sessionThoughts={sessionThoughts} onCompleted={handlePostSessionCompleted}></StartMembershipScreen>
    // }

    if (viewName === "TakeAssessment") {
        mainContent = <TakeAssessmentScreen initialAssessment={activeAssessment} onAssessmentCompleted={handleAssessmentCompleted} ></TakeAssessmentScreen>
    }

    const [show, set] = useState(false);
    const [transitionItems, setTransitionItems] = useState([]);
    const transitions = useTransition(transitionItems, {
        from: { opacity: 0, x: 40 },
        enter: { opacity: 1, x: 0 },
        leave: { opacity: 0, x: 12 },
        reverse: show,
        delay: 0,
        config: config.gentle,
        //onRest: () => setTransitionItems([])
    });



    const [windowDimensions, setWindowDimensions] = useState(null);
    const [isThoughtsLocked, setIsThoughtsLocked] = useState(false);

    useEffect(() => {
        const completedThoughtCount = sessionThoughts.filter(thought => thought.status === "Completed").length;

        if (completedThoughtCount >= 10) {
            return;
            // This used to show the "Turn in Board" button and block 
            // users from clicking new thoughts.
            // I guess we'll need to back at some point???
            setIsThoughtsLocked(true);
            user.is_thoughts_locked = true;
            changeUser({ ...user });
        }

    }, [sessionThoughts]);

    let className = "ThoughtGrid2";

    className += " " + viewName + "Page";

    className += " " + pageEffect;

    let pageHeadingText = new Date().toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric'
    });
    if (viewName === "AssessmentsPeek") {
        pageHeadingText = "My Assessments";
    }
    if (viewName === "TakeAssessment") {
        pageHeadingText = "Drive To Finish";
    }

    if (pageHeadingText.length > 12) {
        className += " ShrunkPageHeading";
    }

    if (pageEffectsRef.current && pageEffectsRef.current.length) {
        className += pageEffectsRef.current.join(" ");
    }

    if (isAnimatingCompletedThought) {
        className += " " + "IsAnimatingCompletedThought";
    }

    if (thought && thought.row) {
        className += " ThemeColor" + thought.row.color;
    }

    let doneButtonClass = "d-button Show";

    if (showResponsesDoneButton) {
        doneButtonClass += " Show";
    }
    if (!showResponsesDoneButton) {
        doneButtonClass += " Hidden";
    }
    if (viewName === "ThoughtPostRating") {
        doneButtonClass += " Leave";
    }





    let exploreItemCount = 0;
    if (thought && thought.exploreItems) {
        exploreItemCount = thought.exploreItems.length;
    } else if (thought && thought.context && thought.context.exploreItems) {
        exploreItemCount = thought.context.exploreItems.length;
    }

    return (
        <div className={className}>



            <AnimationContext.Provider value={animations}>
                <WizardContext.Provider value={wizard}>

                    {/* <div className='main-heading'>
                <div className='heading-prompt'>
                    What's on your mind?
                </div>
            </div> */}

                    {user.is_account_gated && (
                        <div className='GatedOverlay'></div>
                    )}
                    {user.PickGoalsDrawer && (
                        <div className='PickGoalsOverlay'></div>
                    )}

                    {/* <CreateAccountDrawer></CreateAccountDrawer> */}

                    <div className='sidebar'>

                    </div>


                    <div className='main-flex'>

                        <div className='main-nudge'>&nbsp;</div>

                        <div className='main-layout'>



                            <div className='custom-col drop-dest'>

                                <div className='heading-content'>


                                    {["NegativeGrid", "ResponseGrid", "TurnInStack"].includes(viewName) && (
                                        <React.Fragment>
                                            <div className='page-icon'>
                                                <img src="november.png" className='icon-img' />
                                            </div>
                                            <div className='page-heading chat-label'>{pageHeadingText}</div>
                                            <div className='side-content'>
                                                <div className='assessments-bubble' onClick={handleAssessmentBubbleClick}>
                                                    <div className='label'>My Assessments</div>
                                                    <div className='count'>{user.context.assessmentCredits}</div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )}

                                    {["AssessmentsPeek"].includes(viewName) && (
                                        <React.Fragment>
                                            <div className='page-heading chat-label page-heading-assessments page-heading-shrunk'>{pageHeadingText}</div>
                                            <div className='side-content'>
                                                <div className='back-bubble' onClick={handleBackToMainClick}>
                                                    <img src="back-arrow.png" className='back-arrow-img' />
                                                    <div className='label'>My CBT</div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )}

                                    {["TakeAssessment"].includes(viewName) && (
                                        <React.Fragment>

                                            <div className='page-heading-assessment'>

                                                <div className='page-heading chat-label'>
                                                    {activeAssessment.name}
                                                </div>
                                                <img src={`skill-${activeAssessment.icon}.png`} className='icon-img'></img>
                                            </div>

                                        </React.Fragment>
                                    )}

                                </div>

                                {(viewName === "AssessmentsPeek") && (
                                    <React.Fragment>

                                        <AssessmentsPeek onStartAssessment={handleStartAssessment}></AssessmentsPeek>

                                    </React.Fragment>
                                )}

                                {(viewName === "TakeAssessment") && (
                                    <React.Fragment>

                                    </React.Fragment>
                                )}


                                {(viewName === "NegativeGrid" || viewName === "TurnInStack" || viewName === "ThoughtPreRating" || viewName === "PreSession" || viewName === "PostSession") && (
                                    <React.Fragment>

                                        {/* <SessionGrid sessionThoughts={sessionThoughts} isPostSession={viewName === "PostSession" ? true : false}></SessionGrid> */}

                                        <ProgressGrid rows={progressRows} progressPlaceholder={progressPlaceholder} sessionThoughts={sessionThoughts} onCardClick={handleProgressCardClick} isPostSession={viewName === "PostSession" ? true : false}></ProgressGrid>

                                    </React.Fragment>
                                )}

                                {(viewName === "ResponseGrid" || viewName === "ExploreSheet" || viewName === "ThoughtPostRating") && (
                                    <div className={`NegativeThoughtCard-container ${isAnimatingCompletedThought ? 'AnimatedFloat-container' : ''}`}>


                                        <div className={`NegativeThoughtCard Expanded ${isAnimatingCompletedThought ? 'AnimatedFloat' : ''}`} style={{ height: UI.getDropSpaceHeight() }}>

                                            <div className='done-button-container'>
                                                <div className={doneButtonClass}>
                                                    {/* <img src="checkmark-orange.png" className='checkmark-img' />
                                            <div className='checkmark-label'>Great work!</div> */}
                                                    <div className='illustration-container'>
                                                        {/* <img src="notebook.svg" className='notebook-img' /> */}
                                                        <div className="animation-container">
                                                            <img src="julia-writing-alpha.png" className="illustration-img" />
                                                        </div>
                                                    </div>
                                                    <div className='checkmark-label'>Great work!</div>
                                                </div>
                                            </div>

                                            {thought.type === "Negative" && (
                                                <div className={`SessionThought SessionThoughtActive Negative ${thought.isNeutral ? 'IsNeutral' : ''}`}>
                                                    <div className='drop-space'>
                                                        <div className='drop-card Landed'>
                                                            <div className='thought-content-active'>
                                                                <div className='thought-label calc-lines-title'>
                                                                    {thought.thought}
                                                                </div>
                                                                <div className='thought-label calc-lines-tester' id="fontSizeTester"></div>
                                                                <div className={`strike ${isThoughtSingleLine ? 'strike-line1-single' : 'strike-line1'}`} style={{ width: (strikeWidths[0] * .79) + 'px' }}>&nbsp;</div>
                                                                <div className='strike strike-line2' style={{ width: (strikeWidths[1] * .79) + 'px' }}>&nbsp;</div>

                                                                {exploreItemCount > 0 && (
                                                                    <div className='pickup-progress'>

                                                                        <div className='pickup-progressbar'>
                                                                            <div className='pickup-fillbar' style={{ width: '50%' }}></div>
                                                                        </div>
                                                                        <div className='pickup-outof'>{exploreItemCount} <span className='end'>/ 6</span></div>

                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                        </div>

                                        <div id="cardClonesContainer"></div>
                                    </div>
                                )}

                                <div className='LongResponseList List1'>
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map(item => {
                                        const isCompleted = responseList1[item - 1];
                                        const isHighlighted = false;

                                        let itemClassName = 'LongResponseItem';
                                        if (isCompleted) {
                                            itemClassName += ' Completed';
                                        }
                                        if (isHighlighted) {
                                            itemClassName += ' Highlighted';
                                        }

                                        if (responseList1[item - 1] && responseList1[item - 1].isShown) {
                                            itemClassName += ' IsShown';
                                        } else {
                                            itemClassName += ' IsFaded';
                                        }

                                        //itemClassName += " Item" + item;

                                        return (
                                            <div className={itemClassName} id={`LongResponseItem-1-${item}`}>
                                                <div className='item-number'>{item ? item : ''}</div>
                                                <div className='item-content'>{isCompleted ? responseList1[item - 1].sentence : ' '}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className='LongResponseList List2'>
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map(item => {
                                        const isCompleted = responseList2[item - 1];
                                        const isHighlighted = false;

                                        let itemClassName = 'LongResponseItem';
                                        if (isCompleted) {
                                            itemClassName += ' Completed';
                                        }
                                        if (isHighlighted) {
                                            itemClassName += ' Highlighted';
                                        }

                                        if (responseList2[item - 1] && responseList2[item - 1].isShown) {
                                            itemClassName += ' IsShown';
                                        } else {
                                            itemClassName += ' IsFaded';
                                        }

                                        return (
                                            <div className={itemClassName} id={`LongResponseItem-2-${item}`}>
                                                <div className='item-number'>{item ? item + 20 + '' : ''}</div>
                                                <div className='item-content'>{isCompleted ? responseList2[item - 1].sentence : ' '}</div>
                                            </div>
                                        )
                                    })}
                                </div>


                                <div className='page-bottom-content'>
                                    {/* <AirMeter></AirMeter> */}
                                    <div className='MyDesk'>
                                        <div className='desk-heading' style={{ backgroundImage: 'url(desk-bk.jpg)' }}>My Desk</div>

                                        <div className='desk-assessments-card'>
                                            <div className='card-positioning'>
                                                <div className='card-bk' style={{ backgroundImage: 'url(desk-assessments-card.png)' }}></div>
                                                <div className='card-heading'>My Assessments</div>

                                                <div className='card-assessment-lines'>
                                                    <div className='card-assessment-line card-assessment-work'>
                                                        <div className='label'>Work</div>
                                                        <div className='score'>?</div>
                                                    </div>
                                                    <div className='card-assessment-line card-assessment-life'>
                                                        <div className='label'>Life</div>
                                                        <div className='score'>?</div>
                                                    </div>
                                                    <div className='card-assessment-line card-assessment-relationships'>
                                                        <div className='label'>Relationships</div>
                                                        <div className='score'>?</div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className='empty-slot'>
                                            <div className='instructions'>Explore 10 thoughts to begin your <span className='life-path-phase'>Life Path</span></div>
                                        </div>
                                        <div className='empty-slot empty-slot-2'>
                                            <div className='instructions'>Explore 5 thoughts to begin your <span className='life-path-phase'>Journal</span></div>
                                        </div>
                                    </div>
                                </div>

                                {/* <AssessmentReport assessmentRound={user.active_assessments[0]}></AssessmentReport> */}



                                <div className='account-bubble' onClick={handleAccountBubbleClick}>
                                    <img src="account.png" className='account-img' />
                                </div>

                            </div>

                            <CSSTransition nodeRef={nodeRef} in={inProp} timeout={2000} classNames="my-node">
                                <div ref={nodeRef} className='main-content-container'>




                                    {mainContent}




                                </div>
                            </CSSTransition>




                        </div>

                    </div>
                </WizardContext.Provider>
            </AnimationContext.Provider>
        </div >
    );


}

export default ThoughtGrid2;


function calcLines() {

    const titleEl = document.querySelector('.SessionThoughtActive .calc-lines-title');
    const testerEl = document.querySelector('.SessionThoughtActive .calc-lines-tester');

    // Build an array of each word used in the original title
    //var allWords = document.getElementById("title").innerText.match(/\S+/g) || [];
    var allWords = titleEl.innerText.match(/\S+/g) || [];

    // The array we will fill with each line
    var lines = [];
    var lineWidths = [];
    // The current line we are working on building
    var currentLine = "";

    var lastAcceptedWidth = -1;

    // Work through the words until we're filling the correct amount of space
    for (var i = 0; i < allWords.length; i++) {
        // Build a new line and check if it is now too large for the container
        var newLine = currentLine + allWords[i] + " ";
        testerEl.innerText = newLine;
        if (
            testerEl.clientWidth >
            titleEl.clientWidth
        ) {
            // If the line is now larger, use the previous line (without the last added word) and reset the current line to just the last word
            lines.push(currentLine.trim());
            //lineWidths.push(lastAcceptedWidth);
            currentLine = allWords[i] + " ";
            lastAcceptedWidth = -2;
        } else {
            // If it's not long enough yet, just keep adding words
            currentLine = newLine;
            lastAcceptedWidth = testerEl.clientWidth;
        }
    }
    // Push any unfinshed final line to the array
    lines.push(currentLine.trim());

    testerEl.innerText = lines[1];

    for (var i = 0; i < lines.length; i++) {
        var line = lines[i];
        testerEl.innerText = line;
        lineWidths.push(testerEl.clientWidth * 1.31);
    }

    return lineWidths;

}

function getRotatingCompletionNumber(thoughtCount) {
    return (thoughtCount - 1) % 3 + 1;
}

function getRotatingCompletionVideo(number) {
    switch (number) {
        case 1:
            return "pen";
            break;
        case 2:
            return "coffee";
            break;
        case 3:
            return "schedule";
            break;
        case 4:
            return "pen";
            break;
        case 5:
            return "pen";
            break;
        case 6:
            return "pen";
            break;
        // ... (cases for other days)
        default:
            return "coffee";
    }
}

function getRotatingCompletionLabel(number) {
    switch (number) {
        case 1:
            return "Click, Click!";
            break;
        case 2:
            return "Cheers!";
            break;
        case 3:
            return "Case Closed!";
            break;
        case 4:
            return "Click, Click!";
            break;
        case 5:
            return "Click, Click!";
            break;
        case 6:
            return "Click, Click!";
            break;
        // ... (cases for other days)
        default:
            return "Cheers!";
    }
}

function getRotatingCheckLabel(number) {
    switch (number) {
        case 1:
            return "Keep Going!";
            break;
        case 2:
            return "Nice Work!";
            break;
        case 3:
            return "Good Job!";
            break;
        default:
            return "Keep Going!";
    }
}

function getOrdinalSuffix(i) {
    let j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}

function getRandomIntegerBetween(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function getCurrentTime() {
    const now = new Date();
    let hours = now.getHours();
    let minutes = now.getMinutes();
    let seconds = now.getSeconds();

    // Add leading zeros for single-digit values:
    if (hours < 10) {
        hours = "0" + hours;
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }

    return hours + ":" + minutes + ':' + seconds;
}

function getNextStage(stage) {
    const stageDirections = {
        "Acknowledge": "Reframe",
        "Reframe": "Notes",
        "Motivate": "Manifest",
        "Manifest": "Notes"
    };

    return stageDirections[stage];
}