import React, { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import ContentLoader from 'react-content-loader';
import AnimationContext from '../AnimationContext';
import Back from '../helpers/Back';
import PageEffect from '../PageEffect';
import UserContext from '../UserContext';
import './SuggestionsSheet.scss';
import ThoughtSetContent from './ThoughtSetContent';
import UI from './UI';


function SuggestionsSheet({ sessionThoughts, row, options, goal, assessmentRound, color, category, onSelection, onRefreshRequested, onAboutClick }) {

    const animations = useContext(AnimationContext);

    const completedThoughtCount = sessionThoughts.filter(
        i => i.status === "Active" || i.status === "Completed"
    ).length;

    const { user, changeUser } = useContext(UserContext);

    const handleOptionClick = (option, index) => {
        // Workaround right now because whole-screen-click-blocking not working yet in Drawer.
        if (user && (user.is_account_gated || user.is_thoughts_locked)) {
            return;
        }

        option.row = { ...row };
        option.row.items = null; // To prevent circular references with thought sets

        setTimeout(() => {
            animateCard(option, index);
        }, 10);

        //return;

        setTimeout(() => {
            onSelection(option);
        }, 20);
    };

    const animateCard = (option, index) => {
        // Get the current zoom level
        const zoomLevel = parseFloat(getComputedStyle(document.body).zoom) || 1;

        animations.addPageEffect(PageEffect.FadeOutNegativeGrid);

        let optionId = 'suggestions-option-' + row.number + '-' + index;
        const el = document.getElementById(optionId);
        const destEl = document.querySelector('.LongResponseList.List1');

        if (!el || !destEl) {
            console.error('Required elements not found');
            return;
        }

        const elRect = el.getBoundingClientRect();
        const destinationRect = destEl.getBoundingClientRect();

        const newEl = el.cloneNode(true);
        newEl.id = newEl.id + '-clone';
        newEl.classList.add("SessionThoughtExpanded");
        newEl.classList.add("ReinforceInProgress");

        // Initial positioning - relative to the original element
        newEl.style.position = "fixed";
        newEl.style.width = "171px";
        newEl.style.zIndex = 5000;
        newEl.style.boxShadow = "1px 2px 3px 1px #e3e6f7";
        newEl.style.transition = ".4s";

        // Apply initial position with zoom correction
        newEl.style.top = `${(elRect.top - 15) / zoomLevel}px`;
        newEl.style.left = `${(elRect.left - 4.5) / zoomLevel}px`;
        newEl.style.transform = "scale(1)";

        // Add to DOM
        document.querySelector('.ThoughtGrid2').after(newEl);
        el.classList.add("Selected");

        // First animation step - slight scale up
        setTimeout(() => {
            newEl.style.transform = "scale(1.1)";
        }, 10);

        // Second animation step - move to destination
        setTimeout(() => {
            // Get fresh destination coordinates in case anything changed
            const destEl = document.querySelector('.LongResponseList.List1');
            if (!destEl) return;

            const destinationRect = destEl.getBoundingClientRect();
            newEl.style.transform = "scale(1.35)";

            // Calculate center of destination element
            // Add the width of the list element to get the actual destination coordinates
            const destCenterX = destinationRect.left + destinationRect.width;
            const destCenterY = destinationRect.top;

            // Calculate offsets to position the card at the center of destination
            const cardWidth = 171 * 1.35; // width * scale
            const cardHeight = elRect.height * 1.35; // height * scale

            // DESIGN ADJUSTMENT AREA - Safe to modify these values
            // These offsets are relative to the center of the destination element
            // Positive X moves right, negative X moves left
            // Positive Y moves down, negative Y moves up
            let designOffsetX = 148.5;//363; // Adjust this value to move horizontally
            let designOffsetY = 107;//88; // Adjust this value to move vertically

            if (window.innerWidth <= 1360) {
                designOffsetX = 122.5;
                designOffsetY = 98.5;
            }
            if (UI.isSafari()) {
                designOffsetX = 125.5;
                designOffsetY = 99.5;

                if (window.innerWidth <= 1360) {
                    designOffsetX = 63.5;
                    designOffsetY = 93.5;
                }
            }

            // Calculate final position with design adjustments
            const leftOffset = destCenterX - (cardWidth / 2) + designOffsetX;
            const topOffset = destCenterY - (cardHeight / 2) + designOffsetY;

            const topPx = UI.pageTop(topOffset);
            const leftPx = UI.pageLeft(leftOffset);

            console.log('df safari', leftOffset, topOffset, topPx, leftPx);

            // Apply position with zoom correction
            newEl.style.top = `${topOffset / zoomLevel}px`;
            newEl.style.left = `${leftOffset / zoomLevel}px`;
            // newEl.style.top = topPx;
            // newEl.style.left = leftPx;
        }, 400);

        // Final animation step - slight scale down and style adjustments
        setTimeout(() => {
            newEl.style.transform = "scale(1.25)";
            newEl.classList.add("SessionThoughtExpanded");
            newEl.style.boxShadow = "0.5px 1.5px 1px 0px rgba(182, 151, 202, 0.45)";
            newEl.style.backgroundSize = "172px";
        }, 1100);

        // Cleanup
        setTimeout(() => {
            animations.removePageEffect(PageEffect.FadeOutSessionGrid);
            animations.removePageEffect(PageEffect.FadeOutNegativeGrid);
        }, 1450);
    };

    const animateCardOld = (option, index) => {

        //animations.addPageEffect(PageEffect.FadeOutSessionGrid);
        animations.addPageEffect(PageEffect.FadeOutNegativeGrid);

        let optionId = 'suggestions-option-' + row.number + '-' + index;
        const el = document.getElementById(optionId);
        const destEl = document.querySelector('.drop-dest');

        const elRect = el.getBoundingClientRect();
        const destinationRect = destEl.getBoundingClientRect();

        const newEl = el.cloneNode(true);
        newEl.id = newEl.id + '-clone';
        newEl.classList.add("SessionThoughtExpanded");
        newEl.classList.add("ReinforceInProgress");

        newEl.style.position = "fixed";
        newEl.style.top = elRect.top - 4.5 + 'px';
        newEl.style.left = elRect.left + 13.5 + 'px';

        newEl.style.width = "171px";
        // newEl.style.height = "125px";

        // newEl.style.width = "293px";
        // newEl.style.height = "175px";

        //newEl.style.transform = "scale(1.08)";

        //newEl.style.width = "320px";
        //newEl.style.height = "185px";
        newEl.style.transform = "scale(1)";
        newEl.style.top = elRect.top - 20 + 'px';
        newEl.style.left = elRect.left - 38.5 + 'px';
        //newEl.style.transform = "scale(.95)";
        newEl.style.boxShadow = " 1px 2px 3px 1px #e3e6f7";
        newEl.style.transition = ".4s";

        newEl.style.position = "fixed";
        newEl.style.zIndex = 5000;
        newEl.style.top = elRect.top - 4.5 + 'px';
        newEl.style.left = elRect.left + 13.5 + 'px';

        // newEl.style.width = "293px";
        // newEl.style.height = "135px";

        //newEl.style.transform = "scale(1.08)";

        //newEl.style.width = "320px";
        //newEl.style.height = "185px";
        newEl.style.transform = "scale(1)";
        newEl.style.top = elRect.top - 15 + 'px';
        newEl.style.left = elRect.left - 4.5 + 'px';
        //newEl.style.transform = "scale(.95)";
        //newEl.style.boxShadow = " 1px 2px 3px 1px #e3e6f7";
        newEl.style.transition = ".4s";

        document.querySelector('.ThoughtGrid2').after(newEl);

        el.classList.add("Selected");

        //return;

        setTimeout(() => {
            // newEl.style.width = "343px";
            // newEl.style.height = "147px";
            newEl.style.transform = "scale(1.1)";
        }, 10);

        setTimeout(() => {
            const destEl = document.querySelector('.drop-dest');
            const destinationRect = destEl.getBoundingClientRect();

            // DEBT: Totally stumped on why the card is misaligned on larger screens.
            let monitorAdd = window.innerWidth > 1800 ? -1 : -1;

            if (window.innerWidth <= 1380) {
                monitorAdd = -18;
            }

            console.log('monitorAdd', monitorAdd, window.innerWidth);

            newEl.style.transform = "scale(1.35)";

            // newEl.style.width = "294px";
            // newEl.style.height = "114px";
            let topNudge = 102;
            let leftNudge = -230;
            topNudge = 0;
            leftNudge = 0;
            //topNudge = 0;
            //leftNudge = 0;
            newEl.style.top = destinationRect.top + topNudge - 50 + 110 - 56 + 25 + 'px';
            newEl.style.left = destinationRect.left + leftNudge - 60 - 10 + 240 + 270 + 13 + 25 + 31 + monitorAdd + 'px';
            //newEl.style.boxShadow = "0.5px 0.5px 2px 0px #e3e6f7";

        }, 400);

        setTimeout(() => {


            //newEl.style.borderRadius = "18px";
            newEl.style.transform = "scale(1.25)";
            newEl.classList.add("SessionThoughtExpanded");

            newEl.style.boxShadow = "0.5px 1.5px 1px 0px rgba(182, 151, 202, 0.45)";
            newEl.style.backgroundSize = "172px";
            //newEl.style.opacity = .5;



        }, 1100);

        setTimeout(() => {
            animations.removePageEffect(PageEffect.FadeOutSessionGrid);
            animations.removePageEffect(PageEffect.FadeOutNegativeGrid);
            //newEl.remove();
            //newEl.style.opacity = .5;
        }, 1450);
    };

    let className = "SuggestionsSheet";

    className += " " + category;

    className += " Color" + row.color;

    return (
        <div className={className}>
            <div className='category-content'>

                <div className='category-heading'>
                    {category === "Work" ? "I want to make more money" : "I want to have more confidence"}
                </div>
                {/* <img src="work-about-head.png" className='category-img category-work-img' /> */}
            </div>
            <div className='clearfix'></div>

            <div className='options'>

                {row.items && row.items.map((option, index) => {
                    let className = 'SuggestionOption ';
                    let optionId = 'suggestions-option-' + row.number + '-' + index;

                    className += " Option" + (index + 1) + " ";

                    className += " Row" + row.number + " ";

                    className += " Color" + row.color + " ";

                    className += option.type;

                    if (option.isNeutral) {
                        className += " Neutral";
                    }

                    return (
                        <div className={className} id={optionId} onClick={() => handleOptionClick(option, index)} key={optionId} style={{ backgroundImage: 'url(sticky-note-' + row.color + '.png)' }}>
                            <div className='flare flare-tape'><img src="sticky-tape.png" className='tape-img' /></div>
                            {/* <div className='flare flare-pin'><img src="sticky-pin.png" className='pin-img' /></div> */}



                            <div className='label'>
                                <div className='label-content'>{option.thought}</div>
                            </div>

                            {/* <div className='row-label'>{option.oldThought}</div> */}


                            <div className='ProgressCounter'>
                                <div className='number number-current'>{option.exploreItems.length}</div>
                                <div className='slash'>/</div>
                                <div className='number number-goal'>40</div>
                            </div>

                        </div>
                    )
                })}

                {row.items && row.items.length < 1 && (
                    <div className='options-loading-placeholder'></div>
                )}

                <div className={`SuggestionLabel Row${row.number}`}>
                    {row.type === "Area" && (
                        <div className='widget-heading'>
                            {/* <div className='sub-label'>Area</div> */}
                            <div className='label'>{row.number === 3 ? "My work" : "My life"}</div>
                            {/* <img src={`skill-${row.icon}.png`} className='icon'></img> */}



                        </div>
                    )}
                    {row.type === "Goal" && (
                        <div>
                            <div className='sub-label'>Goal</div>
                            <div className='label'>{row.goal.label}</div>
                        </div>
                    )}

                </div>

                <div className='clearfix'></div>


            </div>

            <div className='completed-slots'>
                {[1, 2, 3].map(
                    p => {
                        return (
                            <div className='completed-slot' key={p}>

                            </div>
                        )
                    }
                )}
            </div>

            {row.items && row.items.length < 1 && (
                <div className='negative-loading-placeholders'>
                    {[1, 2, 3].map(
                        p => {
                            return (
                                <div className='negative-loading-placeholder' key={p}>
                                    <ContentLoader viewBox="0 0 400 160"
                                        height={78} width={400}
                                        backgroundColor="#e8d8a9"
                                        foregroundColor="#e6c14f"
                                    >
                                        <rect x="0" y="21" rx="4" ry="4" width="244" height="9" />
                                        <rect x="0" y="45" rx="3" ry="3" width="145" height="9" />
                                    </ContentLoader>
                                </div>
                            )
                        }
                    )}

                </div>
            )}


        </div>
    );


}

export default SuggestionsSheet;
