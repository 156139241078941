import React, { useContext } from 'react';
import { useState, useEffect } from 'react';
import './ProgressGrid.scss';
import ProgressRow from './ProgressRow';
import UserContext from '../UserContext';

function ProgressGrid({ rows, progressPlaceholder, onCardClick, sessionThoughts, isAnimatingCompletedThought, isPostSession }) {

    const { user, changeUser } = useContext(UserContext);

    //const [rows, setRows] = useState(defaultRows(user));
    const [animatingThought, setAnimatingThought] = useState(null);


    useEffect(() => {
        console.log('wer user animating', user, user.animatingCompletedThought);
        // If we're already animating a thought, don't touch anything.
        if (animatingThought) {
            return;
        }

        // We want to wait until the user's state changes 
        // to trigger the animation for a specific thought.
        if (!user.animatingCompletedThought) {
            return;
        }

        if (animatingThought && animatingThought.id === user.animatingCompletedThought.id) {
            console.log('qwe skipping animation trigger because of duplicate ID', newAnimatingThought, user.animatingCompletedThought);
            return;
        }

        // Copy the thought that was just completed by the user 
        // that we need to now animate.
        // This makes sure we're not editing the user data
        // and let's us manipulate it for our purposes.
        const newAnimatingThought = { ...user.animatingCompletedThought };

        // Since it's a multi-step animation via CSS,
        // we unfortunately need a stateful animation flow.
        newAnimatingThought.animationStatus = "FadeInPaperCard";

        //newAnimatingThought.previousExploreCount = 8;
        console.log('qwe adding exploreCount', newAnimatingThought);
        newAnimatingThought.exploreCount = newAnimatingThought.exploreItems?.length || 0;
        // DEBT: above line should never not have exploreItems, can't figure it out yet.
        const isThoughtNew = newAnimatingThought.isNew;

        if (!newAnimatingThought.exploreItems) {
            console.log('qwe no exploreItems unexpected', newAnimatingThought, user);
        }

        // Start animating!
        setTimeout(() => {
            setAnimatingThought(newAnimatingThought);
        }, 1000);

        // Setup the stages of the animation.
        // CSS animation timings need to match these timings! Deal with it.
        setTimeout(() => {
            const nextAnimatingThought = { ...newAnimatingThought };
            nextAnimatingThought.animationStatus = "PushInPaperCard";
            setAnimatingThought(nextAnimatingThought);
        }, 2000);

        setTimeout(() => {
            const nextAnimatingThought2 = { ...newAnimatingThought };
            nextAnimatingThought2.animationStatus = "IncreaseProgressFill";
            setAnimatingThought(nextAnimatingThought2);
        }, 3200);

        setTimeout(() => {
            const nextAnimatingThought3 = { ...newAnimatingThought };
            nextAnimatingThought3.animationStatus = "ShowMessage";
            setAnimatingThought(nextAnimatingThought3);
        }, 4200);

        setTimeout(() => {
            setAnimatingThought(null);
            user.animatingCompletedThought = null;
            changeUser({ ...user });
        }, 5300);

        // setTimeout(() => {
        //     newAnimatingThought.animationStatus = "PushInPaperCard";
        //     setAnimatingThought(newAnimatingThought);
        // }, 4000);
    }, [user]);



    const handleCardClick = (option, index) => {
        console.log('Option clicked', option, index);
    };



    let className = 'ProgressGrid';

    console.log('wer feeding rows', rows);

    return (
        <div className={className}>

            {rows.map(row => <ProgressRow row={row} progressPlaceholder={progressPlaceholder} onCardClick={onCardClick} animatingThought={animatingThought} />)}

        </div>
    );
}

export default ProgressGrid;
