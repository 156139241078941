import React, { useContext } from 'react';
import { useState, useEffect } from 'react';
import AnimationContext from '../AnimationContext';
import './ProgressRow.scss';
import PageEffect from '../PageEffect';
import UI from './UI';

function ProgressRow({ row, progressPlaceholder, onCardClick, animatingThought }) {

    const animations = useContext(AnimationContext);

    let className = 'ProgressRow';

    className += " Color" + row.color;

    const [overCard, setOverCard] = useState(null);
    const [showCardMessageId, setShowCardMessageId] = useState(null);
    const [showCardNewCountId, setShowCardNewCountId] = useState(null);

    const handleCardClick = (option, index) => {
        flingCard(option, index);
        onCardClick(option, index);
    };

    const handleEnterCard = (option, index) => {
        setOverCard(option);
    };

    const handleLeaveCard = (option, index) => {
        console.log('handleLeaveCard', option, index);
        setOverCard(null);
    };

    const flingCard = (option, index) => {

        //animations.addPageEffect(PageEffect.FadeOutSessionGrid);
        animations.addPageEffect(PageEffect.FadeOutNegativeGrid);

        let optionId = 'suggestions-option-progress-' + row.number + '-' + index;
        const el = document.getElementById(optionId);
        const destEl = document.querySelector('.drop-dest');

        console.log('flingCard', option, index, el, destEl);

        const elRect = el.getBoundingClientRect();
        const destinationRect = destEl.getBoundingClientRect();

        const newEl = el.cloneNode(true);
        newEl.id = newEl.id + '-clone';
        newEl.classList.add("SessionThoughtExpanded");
        newEl.classList.add("ReinforceInProgress");

        newEl.style.position = "fixed";
        newEl.style.top = elRect.top - 4.5 + 'px';
        newEl.style.left = elRect.left + 13.5 + 'px';

        newEl.style.width = "171px";
        // newEl.style.height = "125px";

        // newEl.style.width = "293px";
        // newEl.style.height = "175px";

        //newEl.style.transform = "scale(1.08)";

        //newEl.style.width = "320px";
        //newEl.style.height = "185px";
        newEl.style.transform = "scale(1)";
        newEl.style.top = elRect.top - 20 + 'px';
        newEl.style.left = elRect.left - 38.5 + 'px';
        //newEl.style.transform = "scale(.95)";
        newEl.style.boxShadow = " 1px 2px 3px 1px #e3e6f7";
        newEl.style.transition = ".4s";

        newEl.style.position = "fixed";
        newEl.style.zIndex = 5000;
        newEl.style.top = elRect.top - 4.5 + 'px';
        newEl.style.left = elRect.left + 13.5 + 'px';

        // newEl.style.width = "293px";
        // newEl.style.height = "135px";

        //newEl.style.transform = "scale(1.08)";

        //newEl.style.width = "320px";
        //newEl.style.height = "185px";
        newEl.style.transform = "scale(1)";
        newEl.style.top = elRect.top - 4.5 + 'px';
        newEl.style.left = elRect.left - 4 + 'px';
        //newEl.style.transform = "scale(.95)";
        //newEl.style.boxShadow = " 1px 2px 3px 1px #e3e6f7";
        newEl.style.transition = ".4s";

        newEl.classList.remove('HiddenCard');

        console.log('newEl', newEl, el);

        document.querySelector('.ThoughtGrid2').after(newEl);

        //return;

        setTimeout(() => {
            // newEl.style.width = "343px";
            // newEl.style.height = "147px";
            newEl.style.transform = "scale(1.1)";
        }, 10);

        setTimeout(() => {
            const destEl = document.querySelector('.drop-dest');
            const destinationRect = destEl.getBoundingClientRect();

            // DEBT: Totally stumped on why the card is misaligned on larger screens.
            const monitorAdd = window.innerWidth > 1800 ? -1 : -1;

            newEl.style.transform = "scale(1.35)";

            // newEl.style.width = "294px";
            // newEl.style.height = "114px";
            let topNudge = 102;
            let leftNudge = -230;
            topNudge = 0;
            leftNudge = 0;
            //topNudge = 0;
            //leftNudge = 0;
            newEl.style.top = destinationRect.top + topNudge - 50 + 110 - 56 + 25 + 'px';
            newEl.style.left = destinationRect.left + leftNudge - 60 - 10 + 240 + 270 + 13 + 25 + 31 + monitorAdd + 'px';
            //newEl.style.boxShadow = "0.5px 0.5px 2px 0px #e3e6f7";

        }, 400);

        setTimeout(() => {


            //newEl.style.borderRadius = "18px";
            newEl.style.transform = "scale(1.25)";
            newEl.classList.add("SessionThoughtExpanded");

            newEl.style.boxShadow = "0.5px 1.5px 1px 0px rgba(182, 151, 202, 0.45)";
            newEl.style.backgroundSize = "172px";
            //newEl.style.opacity = .5;



        }, 1100);

        setTimeout(() => {
            animations.removePageEffect(PageEffect.FadeOutSessionGrid);
            animations.removePageEffect(PageEffect.FadeOutNegativeGrid);
            //newEl.remove();
            //newEl.style.opacity = .5;
        }, 1450);
    };

    return (
        <div className={className} >

            <div className='cards'>
                {row.items && row.items.map((option, index) => {
                    let className = 'SuggestionOption ProgressCard ';
                    let optionId = 'suggestions-option-progress-' + row.number + '-' + index;

                    const exploreItems = option && option.context.exploreItems ? option.context.exploreItems : null;
                    let exploreCount = option && option.context.exploreItems ? option.context.exploreItems.length : 0;

                    className += " Negative Neutral Option" + (index + 1) + " ";

                    className += " Row" + row.number + " ";

                    className += " Color" + row.color + " ";

                    // Save the class name at this point for later use
                    // for the "temporary" open background we do later.
                    // So we can make it look "Open" even if we actually 
                    // do have a completed thought to render.
                    let openClassName = className + " Open OpenPlaceholder";

                    if (!option) {
                        className += " Open";
                        return (
                            <div className={className} onClick={() => handleCardClick(option, index)} key={optionId}>
                                &nbsp;
                            </div>
                        );
                    }

                    className += option.type;

                    if (option.isNeutral) {
                        className += " Neutral";
                    }

                    let containerClassName = 'ProgressCard-container';

                    let hiddenCardClassName = className + " HiddenCard";
                    hiddenCardClassName = hiddenCardClassName.replace("ProgressCard", "");

                    if (overCard && overCard.thought === option.thought) {
                        className += " OverCard";
                        containerClassName += " OverCard";
                    }

                    let previousExploreCount = exploreCount;
                    let usePreviousExploreCount = false;


                    if (animatingThought && animatingThought.id === option.id) {
                        usePreviousExploreCount = true;
                        previousExploreCount = animatingThought.previousExploreCount;
                        containerClassName += " AnimatingThought";
                        containerClassName += " AnimatingThoughtStatus" + animatingThought.animationStatus;

                        if (animatingThought.animationStatus === "FadeInPaperCard") {
                            console.log('animatingThoughtFadeInPaperCard');
                        }

                        if (animatingThought.animationStatus === "IncreaseProgressFill") {
                            usePreviousExploreCount = false;
                        }
                        if (animatingThought.animationStatus === "ShowMessage") {
                            usePreviousExploreCount = false;
                        }
                    }

                    //console.log('qwe progressPlaceholder check', progressPlaceholder, option);
                    if (progressPlaceholder && progressPlaceholder.id === option.id) {
                        console.log('qwe progressPlaceholder check pass', progressPlaceholder, option);

                        if (progressPlaceholder.isBrandNew) {
                            containerClassName += " IsNewPlaceholder";
                            className += " IsNewPlaceholder";
                            usePreviousExploreCount = true;
                            previousExploreCount = 0;
                        } else {
                            containerClassName += " IsExistingPlaceholder";
                            className += " IsExistingPlaceholder";
                            usePreviousExploreCount = true;
                            previousExploreCount = progressPlaceholder.previousExploreCount;
                        }
                    }


                    let exploreWidth = 0;


                    if (usePreviousExploreCount) {
                        exploreWidth = previousExploreCount ? (previousExploreCount / 40) * 100 + 'px' : '0px';
                    } else {
                        exploreWidth = exploreCount ? (exploreCount / 40) * 100 + 'px' : '0px';
                    }
                    console.log('der exploreWidth calc', usePreviousExploreCount, exploreWidth, previousExploreCount, exploreCount);

                    if (option.isNew) {
                        containerClassName += " IsNew";
                        className += " IsNew";
                    }

                    console.log('qwe exploreWidth', exploreWidth);
                    if (exploreWidth === '0px') {
                        className += " IsProgressFillEmpty";
                    }


                    return (
                        <div className={containerClassName} onMouseEnter={() => handleEnterCard(option, index)} onMouseLeave={() => handleLeaveCard(option, index)}>
                            <div className={className} onClick={() => handleCardClick(option, index)} key={optionId} onMouseEnter={() => handleEnterCard(option, index)} onMouseLeave={() => handleLeaveCard(option, index)}>

                                <div className='progress-fill' style={{ height: exploreWidth }}></div>


                                <div className='label'>
                                    <div className='label-content'>{option.thought}</div>
                                </div>

                                {/* <div className='row-label'>{option.oldThought}</div> */}


                                <div className='ProgressCounter'>
                                    <div className='number number-current'>{usePreviousExploreCount ? previousExploreCount : exploreCount}</div>
                                    <div className='slash'>/</div>
                                    <div className='number number-goal'>40</div>
                                </div>


                                {animatingThought && animatingThought.id === option.id && animatingThought.animationStatus === "ShowMessage" && (
                                    <div className='GreatJobMessage'>
                                        <div className='message-label'>Great&nbsp;job!</div>
                                    </div>
                                )}

                            </div>

                            <div className={hiddenCardClassName} id={optionId} onClick={() => handleCardClick(option, index)} key={`${optionId}-hidden`} style={{ backgroundImage: 'url(sticky-note-' + row.color + '.png)' }}>
                                <div className='flare flare-tape'><img src="sticky-tape.png" className='tape-img' /></div>
                                {/* <div className='flare flare-pin'><img src="sticky-pin.png" className='pin-img' /></div> */}



                                <div className='label'>
                                    <div className='label-content'>{option.thought}</div>
                                </div>

                                {/* <div className='row-label'>{option.oldThought}</div> */}


                                <div className='ProgressCounter'>
                                    <div className='number number-current'>{exploreCount}</div>
                                    <div className='slash'>/</div>
                                    <div className='number number-goal'>40</div>
                                </div>
                            </div>

                            <div className={openClassName}>&nbsp;</div>
                        </div>
                    )
                })}
            </div>

        </div >
    );
}

export default ProgressRow;
