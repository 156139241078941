import Axios from 'axios';
import Cookies from 'js-cookie';

if (!Cookies.get("auth-token")) {
    //Cookies.set("auth-token", "eyJpdiI6Ii9sZnlpVGpaYU5RR0NUdGtZVC9hdVE9PSIsInZhbHVlIjoiNWZZdWJmTEU5MjJkbWhsYkxiNWx5QT09IiwibWFjIjoiMGUwY2Q0MWJkODgyYWE0MmIwZmJhMjJiMWVjOWZiZjFmYTE1MjBiOTg2ZjFkY2M0YjVjMTJjYWYzOTM1MmZjYSIsInRhZyI6IiJ9");
    console.log('set cookie');
}

let authToken = Cookies.get("auth-token");
const apiUrl = process.env.REACT_APP_API_URL;
//const apiUrl = "http://127.0.0.1:8002/api";

export default {

    isSignedIn: () => {
        //return false; // TODO: TESTING`
        return authToken && authToken.length > 0;
    },

    getNegativeThoughts: async (row, sliderValues) => {
        return await get("/negative_thoughts", { row, sliderValues });
    },

    getReinforcingThoughts: async (previousResponses) => {
        return await get("/reinforcing_thoughts", {});
    },

    respondToThought: async (thought, exploreItems, previousResponses, group, count, isReinforce, voice, clickedResponses, purpose, row, responseStage) => {
        const newResponses = clickedResponses.map(response => {
            const newResponse = { ...response };
            delete newResponse.option;
            delete newResponse.isFaded;
            delete newResponse.isShown;
            delete newResponse.isStrongPress;
            delete newResponse.area;
            return newResponse;
        });

        return await get("/respond_to_thought", { 'thought': thought, 'exploreItems': exploreItems, 'previous_responses': [], 'group': group, 'count': count, 'is_reinforce': isReinforce, 'voice': voice, 'responseStage': responseStage, 'clicked_responses': newResponses, 'purpose': purpose, 'row': row });
    },
    respondToReinforcementThought: async (thought, exploreItems, previousResponses, group, count, isReinforce, voice, clickedResponses) => {
        const shortClickedResponses = clickedResponses.map(({ option, isStrongPress, isFaded, ...rest }) => rest); // Prevent CORS errors because of truncation I believe, once the user gets close to 40 clicked responses.
        //return await get("/respond_to_reinforcement_thought", { 'thought': thought, 'exploreItems': exploreItems, 'previous_responses': previousResponses, 'group': group, 'count': count, 'is_reinforce': isReinforce, 'voice': voice });
        return await get("/respond_to_thought", { 'thought': thought, 'exploreItems': exploreItems, 'previous_responses': previousResponses, 'group': group, 'count': count, 'is_reinforce': isReinforce, 'voice': voice, 'clicked_responses': shortClickedResponses });
    },

    expandOnThought: async (expandThought, originalThought, exploreItems) => {
        return await get("/expand_on_thought", { expandThought, originalThought, exploreItems })
    },

    getReframingOptions: async (thought, category, isRefresh) => {
        return await get("/suggest_reframing", { thought, category, isRefresh });
    },

    recordCompletedThought: async (completedThought) => {
        console.log('TT recordCompletedThought', completedThought);
        return await post("/completed_thoughts/record", { ...completedThought });
    },

    recordCompletedQuestion: async (question) => {
        return true;
        //return await post("/questions/record", { ...question });
    },

    saveAbout: async (answer, category) => {
        return await post("/users/save_about", { answer, category });
    },

    saveGoals: async (goals) => {
        return await post("/users/save_goals", { goals });
    },

    saveAssessment: async (type, identifier, score, questions) => {
        return await post("/users/save_assessment", { type, identifier, score, questions });
    },

    increaseMoodMeter: async () => {
        return await post("/users/increase_mood_meter", {});
    },

    addDonation: async (amount) => {
        return await post("/users/add_donation", { amount });
    },

    saveContext: async (name, value) => {
        return await post("/users/save_context", { name, value });
    },

    savePhoneNumber: async (phoneNumber) => {
        return await post("/users/save_phone_number", { phoneNumber });
    },

    turnInStack: async (charityName) => {
        return await post("/users/turn_in_stack", { charityName });
    },

    createCheckoutSession: async (answer, category) => {
        return await post("/checkout/create_session", {});
    },

    refreshToken: function () {
        authToken = Cookies.get("auth-token");
    },

    activityEvents: async () => {
        return await get("/activity_events");
    },

    markNotificationsAsRead: async () => {
        return await post("/activity_events/mark_as_read");
    },

    user: async () => {
        return await get("/users/handshake");
    },

    createTemporaryUser: async () => {
        const searchParams = new URLSearchParams(window.location.search);
        const queryParamsObject = Object.fromEntries(searchParams.entries());

        return await post("/users/create_temporary", { query: queryParamsObject });
    },

    markDeleteMyAccount: async () => {
        return await post("/users/mark_delete_my_account");
    },

    registerTemporaryUser: async (email, password) => {
        return await post("/users/register_temporary", { email, password });
    },

    startSlam: async (questions) => {
        return await post("/slams/start", { questions });
    },

    finishSlam: async (id, outcomeAnswer) => {
        return await post("/slams/finish", { id, outcomeAnswer });
    },

    signIn: async (username, password) => {
        return await post("/users/sign_in", { 'email': username, 'password': password });
    },

    createProject: async (title, healthName) => {
        return await post("/projects", { 'title': title, 'health_name': healthName });
    },

    completeProject: async (projectId) => {
        return await post("/projects/complete", { 'project_id': projectId });
    },

    trashProject: async (projectId) => {
        return await post("/projects/trash", { 'project_id': projectId });
    },

    startProjectStage: async (projectId, healthName) => {
        return await post("/projects/start_stage", { 'project_id': projectId, 'health_name': healthName });
    },

    completeSession: async (session_id, project_id, par, final, difficulty, rank, thought_label) => {
        return await post("/sessions/complete", { session_id, project_id, par, final, difficulty, rank, thought_label });
    },

    completeDay: async () => {
        return await post("/users/complete_day", {});
    },

    levelUpSession: async (session_id) => {
        return await post("/sessions/level_up", { session_id });
    },

    suggestThoughts: async (project_id, rank) => {
        return await get("/sessions/thought_options", { project_id, rank });
    },

    completeWizardStep: async (step_name) => {
        return await post("/users/wizard/complete", { step_name });
    },

    attachBonusItem: async (bonusItem, project) => {
        return await post("/projects/attach_bonus_item", { 'bonus_item_id': bonusItem.id, 'project_id': project.id })
    },

    reorderProject: async (project_id, direction) => {
        return await post("/projects/reorder", { project_id, direction });
    },

    completeSessionLine: async (project_id, rank) => {
        return await post("/projects/complete_session_line", { project_id, rank });
    },

    sendMessage: async (recipient_id, sender_id, label, emojis) => {
        return await post("/messages/send", { recipient_id, sender_id, label, emojis });
    },

    todayMessages: async () => {
        return await get("/messages/today")
    },

    setTodayGoal: async (minutes) => {
        return await post("/users/set_today_goal", { minutes });
    },

    setProjectEstimate: async (project_id, estimate) => {
        return await post("/projects/set_estimate", { project_id, estimate });
    },

    socialSuggest: async () => {
        return await get("/social/suggest", {});
    },

    recordEvent: async (name, value, secondary_value, context) => {
        if (!context || typeof context !== 'object' || Array.isArray(context)) {
            context = {};
        }

        // Get the user agent
        context.userAgent = navigator.userAgent;

        // Get viewport width and height
        context.viewport = {
            width: window.innerWidth,
            height: window.innerHeight
        };

        return await post("/users/record_event", { name, value, secondary_value, context })
    },
};

async function get(url, params, raw) {
    try {
        const response = await Axios.get(apiUrl + url, { headers: headers(), params });
        if (raw) {
            return response;
        }
        return readResponse(response);
    } catch (err) {
        console.log('GET request error', err);
        return false;
    }
}

async function put(url, resource) {

}

async function post(url, resource) {
    try {
        const response = await Axios.post(apiUrl + url, resource, { headers: headers() });
        return readResponse(response);
    } catch (err) {
        console.log('POST request error', err);
        return false;
    }
}

function readResponse(response) {
    const { data } = response;



    if (data.success !== true) {


        return false;
    }

    return data.data;
}

function headers() {
    //console.log('cookie headers', authToken);
    return {
        "X-Auth-Token": authToken
    };
}

function isIos() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
};