import React, { useContext, useEffect, useRef, useState } from 'react';
import Back from '../helpers/Back';
import UserContext from '../UserContext';
import './ResponseGrid.scss';
import ResponseLoadingGrid from './ResponseLoadingGrid';
import UI from './UI';
import WizardPop from './WizardPop';
import ResponseReactions from './ResponseReactions';
import AnimationContext from '../AnimationContext';



function ResponseGrid({ options, responseStage, isLoading, isFakeLoading, thought, voice, responses, responseList1, responseList2, onResponseAdded, onRefreshRequested, onStageCompleted, onVoiceChanged }) {

    const { user, changeUser, handlePop } = useContext(UserContext);

    const [selectedOptions, setSelectedOptions] = useState([]);

    const [showDoneButton, setShowDoneButton] = useState(true);
    const [isCheckpointOpen, setIsCheckpointOpen] = useState(false);
    const [brainSize, setBrainSize] = useState(1.2);
    const [brainAnimation, setBrainAnimation] = useState(false);
    const [brainScores, setBrainScores] = useState(null);

    const [isPop1Open, setIsPop1Open] = useState(true);
    const [isPop2Open, setIsPop2Open] = useState(false);

    const [isPopAcknowledgeOpen, setIsPopAcknowledgeOpen] = useState(false);
    const [isPopReframeOpen, setIsPopReframeOpen] = useState(false);
    const [isPopMotivateOpen, setIsPopMotivateOpen] = useState(false);
    const [isPopManifestOpen, setIsPopManifestOpen] = useState(false);

    const [delaySecondPop, setDelaySecondPop] = useState(false);

    const [stageResponseCount, setStageResponseCount] = useState(0);
    const [markedBadOptions, setMarkedBadOptions] = useState([]);

    const fillingOption = useRef();

    const minimumResponseCount = 5;

    const animations = useContext(AnimationContext);

    useEffect(() => {

        console.log('qwe user check', user);

        if (user.pendingPops.includes("startResponses")) {
            setDelaySecondPop(true);
            handlePop("startResponses", setIsPop1Open);
            return;
        }

    }, []);

    useEffect(() => {
        console.log('wer watch responseStage', responseStage);
        if (responseStage === "Reframe") {
            handlePop("reframeStage", setIsPopReframeOpen);
        }
        if (responseStage === "Manifest") {
            handlePop("manifestStage", setIsPopManifestOpen);
        }
    }, [responseStage]);

    const handleCheckpointClick = () => {

        if (stageResponseCount < 3) {
            return;
        }

        setBrainAnimation("Expand");
        //setStageResponseCount(0);
        //onStageCompleted();

        // setTimeout(() => {
        //     setBrainAnimation("Shrink");
        // }, 1200);

        setTimeout(() => {
            setBrainAnimation("SinkIn");
        }, 1100);

        setTimeout(() => {
            setBrainAnimation("HeartBeat");
        }, 1800);

        setTimeout(() => {
            setBrainAnimation("Ending");

            // Decrease the displayed brain count one by one s
            // for a satisfying effect.
            // Slow down the count once it gets to double digits
            // like the old video games, because why the fuck not?
            function scheduleDecrement(i = 0, totalIterations = 50) {
                if (i >= totalIterations) return; // Stop when we've done all iterations

                // Update state and use the value directly in the callback
                setStageResponseCount(prev => {
                    const newCount = prev < 1 ? 0 : prev - 1;
                    // Determine timing based on the updated count
                    const timing = newCount < 10 ? 90 : 60;

                    // Schedule the next iteration after the appropriate delay
                    setTimeout(() => {
                        scheduleDecrement(i + 1, totalIterations);
                    }, timing);

                    return newCount;
                });
            }

            // Start the process
            scheduleDecrement();
        }, 2000);

        setTimeout(() => {
            const scores = splitIntoThreeRandomNumbers(stageResponseCount);

            const labels = [
                getRandomString(['Self-motivation', 'Self-love']),
                getRandomString(['Decision-making', 'Intentionality']),
                getRandomString(['Growth mindset', 'Open-mindedness']),
            ];

            setBrainScores({
                [labels[0]]: scores[0],
                [labels[1]]: scores[1],
                [labels[2]]: scores[2],
            });

            //animations.addPageEffect("FadeOutResponseGrid");
        }, 3000);

        setTimeout(() => {
            setBrainScores(null);
        }, 4800);

        setTimeout(() => {
            onStageCompleted();
        }, 5200);

        setTimeout(() => {
            setMarkedBadOptions([]);
            setBrainAnimation(null);
            setStageResponseCount(0);
        }, 5600);
    };

    const handleCheckpointEnter = () => {
        if (brainAnimation) {
            return;
        }

        setBrainAnimation("Shrink");
    };

    const handleCheckpointLeave = () => {
        if (brainAnimation && brainAnimation !== "Shrink") {
            return;
        }

        setBrainAnimation(null);
    };

    const handleFollowPops = () => {
        console.log('wer handleFollowPops');
        console.log('werhandle what', responseStage);
        if (responseStage === "Acknowledge") {
            console.log('handle acknowledge', user.pendingPops);
            handlePop("acknowledgeStage", setIsPopAcknowledgeOpen);
        }
        if (responseStage === "Reframe") {
            handlePop("reframeStage", setIsPopReframeOpen);
        }
        if (responseStage === "Motivate") {
            handlePop("motivateStage", setIsPopMotivateOpen);
        }
        if (responseStage === "Manifest") {
            handlePop("manifestStage", setIsPopManifestOpen);
        }
    };

    const flingCard = (option, index, isStrongPress) => {
        // Get the current zoom level
        const zoomLevel = parseFloat(getComputedStyle(document.body).zoom) || 1;

        let optionId = 'response-option-' + index;
        const el = document.getElementById(optionId);

        el.querySelector('.ResponseReactions').classList.add('Completed');

        console.log('fling card', option, thought, responses);

        let itemId;
        let thisList;
        if (responseList1.length < 20) {
            thisList = responseList1;
            itemId = 'LongResponseItem-1-' + (thisList.length + 1);
        } else {
            thisList = responseList2;
            itemId = 'LongResponseItem-2-' + (thisList.length + 1);
        }

        const elRect = el.getBoundingClientRect();
        const destinationRect = document.getElementById(itemId).getBoundingClientRect();
        const destinationEarlyRect = document.querySelector('.SessionThoughtExpanded').getBoundingClientRect();

        const newEl = el.cloneNode(true);
        newEl.id = newEl.id + '-clone';
        newEl.style.position = "fixed";
        newEl.style.top = `${(elRect.top - 4.5) / zoomLevel}px`;
        newEl.style.left = `${(elRect.left + 13.5) / zoomLevel}px`;
        newEl.style.width = "280px";
        newEl.style.transform = "scale(1.1)";
        newEl.style.boxShadow = "1px 2px 3px 1px #e3e6f7";
        newEl.style.zIndex = 1;
        document.getElementById('cardClonesContainer').appendChild(newEl);

        el.classList.add("Selected");
        newEl.classList.add("Flinging");
        newEl.style.zIndex = 5001;

        newEl.style.transition = ".29s";

        // Update these positions with zoom correction
        newEl.style.top = `${(elRect.top - 4.5) / zoomLevel}px`;
        newEl.style.left = `${(elRect.left + 13.5) / zoomLevel}px`;

        if (isStrongPress) {
            el.classList.add("IsStrongPress");
        }

        setTimeout(() => {
            newEl.style.transform = "scale(1.2)";
        }, 10);

        setTimeout(() => {
            newEl.style.transform = "scale(1.2)";

            const shimmerEl = document.createElement('div');
            shimmerEl.className = 'shimmer-animation';
            newEl.querySelector('.shimmer-container').appendChild(shimmerEl);
        }, 100);

        setTimeout(() => {
            const shimmerElRemove = newEl.querySelector('.shimmer-animation');
            if (shimmerElRemove) {
                shimmerElRemove.remove();
            }

            const shimmerEl2 = document.createElement('div');
            shimmerEl2.className = 'shimmer-animation shimmer-animation-2';
            newEl.querySelector('.shimmer-container').appendChild(shimmerEl2);
        }, 650);

        let flingAddTime = 600;

        setTimeout(() => {
            // Apply zoom correction to the destination coordinates
            newEl.style.top = `${(destinationRect.top - 30 + 20) / zoomLevel}px`;
            newEl.style.left = `${(destinationRect.left + 20) / zoomLevel}px`;
            newEl.style.transform = "scale(0.9)";
            newEl.style.boxShadow = "none";
        }, 80 + flingAddTime);

        setTimeout(() => {
            newEl.style.boxShadow = "none";
            newEl.style.transform = "scale(0.95)";
        }, 400 + flingAddTime);

        setTimeout(() => {
            // Apply zoom correction to final position
            newEl.style.top = `${(destinationRect.top + 50) / zoomLevel}px`;
            newEl.style.opacity = "0";
            newEl.style.transition = ".24s ease-out";
        }, 700 + flingAddTime);
    };

    const flingCardOld = (option, index, isStrongPress) => {
        let optionId = 'response-option-' + index;
        const el = document.getElementById(optionId);

        el.querySelector('.ResponseReactions').classList.add('Completed');

        console.log('fling card', option, thought, responses);

        let itemId;
        let thisList;
        if (responseList1.length < 20) {
            thisList = responseList1;
            itemId = 'LongResponseItem-1-' + (thisList.length + 1);
        } else {
            thisList = responseList2;
            itemId = 'LongResponseItem-2-' + (thisList.length + 1);
        }

        const elRect = el.getBoundingClientRect();
        const destinationRect = document.getElementById(itemId).getBoundingClientRect();
        //const destinationRect = document.querySelector('.SessionThoughtExpanded').getBoundingClientRect();
        const destinationEarlyRect = document.querySelector('.SessionThoughtExpanded').getBoundingClientRect();

        const newEl = el.cloneNode(true);
        newEl.id = newEl.id + '-clone';
        newEl.style.position = "fixed";
        newEl.style.top = elRect.top - 4.5 + 'px';
        newEl.style.left = elRect.left + 13.5 + 'px';
        newEl.style.width = "280px";
        newEl.style.transform = "scale(1.1)";
        newEl.style.boxShadow = "1px 2px 3px 1px #e3e6f7";
        newEl.style.zIndex = 1;
        document.getElementById('cardClonesContainer').appendChild(newEl);

        el.classList.add("Selected");
        newEl.classList.add("Flinging");
        newEl.style.zIndex = 1;

        newEl.style.transition = ".29s";

        // top: 376.106px; left: 612.662px;
        // top: 116.106px; left: -5.662px;
        newEl.style.top = elRect.top - 4.5 + 'px';
        newEl.style.left = elRect.left + 13.5 + 'px';

        //newEl.classList.add('flying-card');


        //return;

        if (isStrongPress) {
            el.classList.add("IsStrongPress");
        }

        setTimeout(() => {
            newEl.style.transform = "scale(1.2)";
        }, 10);

        setTimeout(() => {
            newEl.style.transform = "scale(1.2)";

            const shimmerEl = document.createElement('div');
            shimmerEl.className = 'shimmer-animation';
            newEl.querySelector('.shimmer-container').appendChild(shimmerEl);
        }, 100);

        setTimeout(() => {
            const shimmerElRemove = newEl.querySelector('.shimmer-animation');
            if (shimmerElRemove) {
                shimmerElRemove.remove();
            }

            const shimmerEl2 = document.createElement('div');
            shimmerEl2.className = 'shimmer-animation shimmer-animation-2';
            newEl.querySelector('.shimmer-container').appendChild(shimmerEl2);
        }, 650);

        // setTimeout(() => {
        //     const shimmerElRemove = newEl.querySelector('.shimmer-container');
        //     if (shimmerElRemove) {
        //         shimmerElRemove.remove();
        //     }
        //     const shimmerElRemove2 = newEl.querySelector('.shimmer-container');
        //     if (shimmerElRemove2) {
        //         shimmerElRemove2.remove();
        //     }
        // }, 1200);

        //return;

        let flingAddTime = 600;

        // setTimeout(() => {
        //     newEl.style.top = destinationEarlyRect.top + 0 + 'px';
        //     newEl.style.left = destinationEarlyRect.left + 20 + 'px';
        //     newEl.style.transform = "scale(0.9)";
        //     newEl.style.boxShadow = "none";
        // }, 130);

        setTimeout(() => {
            //newEl.style.setProperty('--final-x', destinationRect.left + 20 + 200 + 'px');
            //newEl.style.setProperty('--final-y', destinationRect.top - 30 + 50 + 200 + 'px');

            newEl.style.top = destinationRect.top - 30 + 20 + 'px';
            newEl.style.left = destinationRect.left + 20 + 'px';
            newEl.style.transform = "scale(0.9)";
            newEl.style.boxShadow = "none";
        }, 80 + flingAddTime);

        setTimeout(() => {
            newEl.style.boxShadow = "none";
            newEl.style.transform = "scale(0.95)";
        }, 400 + flingAddTime);

        setTimeout(() => {
            newEl.style.top = destinationRect.top + 50 + 'px';
            newEl.style.opacity = "0";
            newEl.style.transition = ".24s ease-out";
        }, 700 + flingAddTime);
    };

    const flingEmoji = (option, index, isStrongPress) => {
        // Get the current zoom level
        const zoomLevel = parseFloat(getComputedStyle(document.body).zoom) || 1;
        const isSafari = UI.isSafari();
        console.log('zoomLevel', isSafari, getComputedStyle(document.body).zoom);

        let optionId = 'response-emoji-' + index + '-Happy';
        const el = document.getElementById(optionId);

        el.id = el.id + '-Landed-';

        const elRect = el.getBoundingClientRect();
        const itemId = 'LongResponseItem-' + (responses.length + 1);
        const destinationRect = document.querySelector('.brain').getBoundingClientRect();

        const newEl = el.cloneNode(true);
        newEl.id = newEl.id + '-clone';
        newEl.style.position = "fixed";

        // Apply zoom correction to all pixel measurements
        newEl.style.top = `${(elRect.top - 44.5) / zoomLevel}px`;
        newEl.style.left = `${(elRect.left + 33.5) / zoomLevel}px`;
        newEl.style.top = `${(elRect.top - 62.5 - 2 + 4) / zoomLevel}px`;
        newEl.style.left = `${(elRect.left + 40.5 - 10) / zoomLevel}px`;

        // newEl.style.top = `-4.5px`;
        // newEl.style.left = `13.5px`;
        newEl.style.width = "280px";
        newEl.style.transform = "scale(1.0)";
        newEl.style.boxShadow = "1px 2px 3px 1px #e3e6f7";
        newEl.style.zIndex = 5000 + "";
        document.getElementById('cardClonesContainer').appendChild(newEl);

        el.classList.add("Selected");
        newEl.style.zIndex = 6000;

        newEl.style.transition = ".17s ease-out";

        if (isStrongPress) {
            el.classList.add("IsStrongPress");
        }

        setTimeout(() => {
            newEl.style.transform = "scale(1.1) rotate(48deg)";
        }, 10);

        // setTimeout(() => {
        //     newEl.style.top = newEl.style.top + 100;
        // }, 60);

        let flingAddTime = -40;

        setTimeout(() => {
            // Apply zoom correction to destination coordinates
            newEl.style.top = `${(destinationRect.top - 74 + 84 - 30 + 48 - 85) / zoomLevel}px`;
            newEl.style.left = `${(destinationRect.left - 22 - 84 + 158 + 20 + 162 - 34 - 121) / zoomLevel}px`;
            if (isSafari) {
                newEl.style.top = `${(destinationRect.top - 72 + 84 - 30 + 48 - 85) / 1}px`;
                newEl.style.left = `${(destinationRect.left - 38 - 84 + 158 + 20 + 162 - 34 - 121) / 1}px`;
            }
            newEl.style.transform = "scale(0.4)";
            newEl.style.boxShadow = "none";
            newEl.style.transition = "0.4s";
        }, 130 + flingAddTime);

        setTimeout(() => {
            const degreeNudge = getRandomInteger(-30, 30);
            newEl.style.boxShadow = "none";
            newEl.style.transform = `scale(0.75) rotate(${degreeNudge}deg)`;
            newEl.style.transition = "0.4s";
            newEl.style.opacity = 0.0;
        }, 400 + flingAddTime);
    };

    const flingEmojiOld = (option, index, isStrongPress) => {
        let optionId = 'response-emoji-' + index + '-Happy';
        const el = document.getElementById(optionId);

        el.id = el.id + '-Landed-';

        const elRect = el.getBoundingClientRect();
        const itemId = 'LongResponseItem-' + (responses.length + 1);
        const destinationRect = document.querySelector('.brain').getBoundingClientRect();
        //const destinationRect = document.querySelector('.DailyGoal').getBoundingClientRect();

        const newEl = el.cloneNode(true);
        newEl.id = newEl.id + '-clone';
        newEl.style.position = "fixed";
        newEl.style.top = elRect.top - 4.5 + 'px';
        newEl.style.left = elRect.left + 13.5 + 'px';
        newEl.style.width = "280px";
        newEl.style.transform = "scale(1.0)";
        newEl.style.boxShadow = "1px 2px 3px 1px #e3e6f7";
        newEl.style.zIndex = 5000 + "";
        document.getElementById('cardClonesContainer').appendChild(newEl);

        if (index % 2 === 0) {
            newEl.style.top = elRect.top - 44.5 - 2 + 4 + 'px';
            newEl.style.left = elRect.left + 62.5 - 10 + 'px';
        } else {
            newEl.style.top = elRect.top - 44.5 - 2 + 4 + 'px';
            newEl.style.left = elRect.left + 62.5 + 10 + 'px';
        }

        el.classList.add("Selected");
        newEl.style.zIndex = 6000;

        newEl.style.transition = ".37s ease-out";
        newEl.style.transition = ".17s ease-out";

        // top: 376.106px; left: 612.662px;
        // top: 116.106px; left: -5.662px;
        // newEl.style.top = elRect.top - 44.5 - 2 + 'px';
        // newEl.style.left = elRect.left + 62.5 + 'px';

        //return;

        if (isStrongPress) {
            el.classList.add("IsStrongPress");
        }

        setTimeout(() => {
            newEl.style.transform = "scale(1.1) rotate(48deg)";
        }, 10);

        // setTimeout(() => {
        //     newEl.style.top = newEl.style.top + 100;
        // }, 60);

        //let flingAddTime = 100;
        let flingAddTime = -40;

        // setTimeout(() => {
        //     newEl.style.top = destinationEarlyRect.top + 0 + 'px';
        //     newEl.style.left = destinationEarlyRect.left + 20 + 'px';
        //     newEl.style.transform = "scale(0.9)";
        //     newEl.style.boxShadow = "none";
        // }, 130);

        setTimeout(() => {
            // const topNudge = getRandomInteger(-40, 58);
            // const leftNudge = getRandomInteger(-50, 90);

            // was -50 and -80 for brain
            newEl.style.top = destinationRect.top - 67 + 84 - 30 + 48 - 85 + 'px';
            newEl.style.left = destinationRect.left - 84 + 158 + 20 + 162 - 34 - 121 + 'px';
            newEl.style.transform = "scale(0.4)";
            newEl.style.boxShadow = "none";
            newEl.style.transition = "0.4s";
        }, 130 + flingAddTime);

        setTimeout(() => {
            const degreeNudge = getRandomInteger(-30, 30);
            newEl.style.boxShadow = "none";
            newEl.style.transform = "scale(0.75)  rotate(" + degreeNudge + "deg)";
            newEl.style.transition = "0.4s";
            newEl.style.opacity = 0.0;
        }, 400 + flingAddTime);

        setTimeout(() => {
            //newEl.style.top = destinationRect.top - 0 + 'px';
            //newEl.style.opacity = "0";
        }, 600 + flingAddTime);
    };

    const handleDoneClicked = () => {
        console.log('DF start');
        setStageResponseCount(0);
        //setShowDoneButton(false);
        onStageCompleted();
    };

    const handleEmojiClick = (reaction, option, optionIndex) => {
        console.log('Emoji clicked2', reaction);

        if (reaction.name === "Happy") {
            handleOptionClick(option, optionIndex, false);
        }

        if (reaction.name === "Bad") {
            Back.recordEvent("MarkedBad", option);
            setMarkedBadOptions(prev => {
                return [...prev, option];
            });
        }

        //onRefreshRequested();
    };

    const handleOptionClick = (option, index, isStrongPress) => {
        Back.recordEvent("ClickedResponse", option);

        // if (!brainAnimation) {
        //     setBrainAnimation("Expand");
        // } else if (brainAnimation === "Expand") {
        //     setBrainAnimation("Rotate");
        //     setTimeout(() => {
        //         //setBrainAnimation("Shrink");
        //     }, 2000);
        // } else if (brainAnimation === "Rotate") {
        //     setBrainAnimation("Shrink");
        // } else if (brainAnimation === "Shrink") {
        //     setBrainAnimation("Shake");
        // } else {
        //     setBrainAnimation(false);
        // }

        if (selectedOptions.indexOf(option) !== -1) {
            return;
        }

        setSelectedOptions(prev => {
            return [...prev, option];
        });
        setTimeout(() => {
            setStageResponseCount(prev => {
                return prev += 1;
            });
        }, 550);

        setBrainSize(prev => {
            //return prev += .02;
        });
        console.log('stageResponseCount', responseList1, responseList2, stageResponseCount);

        if (stageResponseCount >= minimumResponseCount - 1) {
            setShowDoneButton(true);
        }

        flingCard(option, index, isStrongPress);
        flingEmoji(option, index, isStrongPress);

        const totalResponseCount = responseList1.length + responseList2.length + 1;
        console.log('totalResponseCount', totalResponseCount, totalResponseCount % 8 === 0);
        // if (totalResponseCount % 8 === 0 || totalResponseCount === 40) {
        //     setIsCheckpointOpen(true);
        // } else {
        //     setIsCheckpointOpen(false);
        // }

        setTimeout(() => {
            onResponseAdded(option, index, isStrongPress);
        }, 200);
    };

    const handleVoiceClicked = (voice) => {
        //setSelectedVoice(voice);
        onVoiceChanged(voice);
    };

    const handleOptionPress = (option, index) => {
        return;
        fillingOption.current = index;
        setTimeout(() => {
            if (fillingOption.current) {
                return;
            }
            let optionId = 'response-option-' + fillingOption.current;
            let cardEl = document.getElementById(optionId);
            let fillEl = cardEl.querySelector('.filler-fill');
            cardEl.classList.add('Filling');
        }, 50);

        setTimeout(() => {
            if (!fillingOption.current) {
                return;
            }
            let optionId = 'response-option-' + fillingOption.current;
            let cardEl = document.getElementById(optionId);
            let fillEl = cardEl.querySelector('.filler-fill');
            cardEl.classList.remove('Filling');

            handleOptionClick(option, index, true);
        }, 700);
    };

    const handleOptionOff = (option, index) => {
        return;
        let optionId = 'response-option-' + fillingOption.current;
        let cardEl = document.getElementById(optionId);
        cardEl.classList.remove('Filling');
        fillingOption.current = null;
    };


    const voiceOptions = ["Explore", "Become", "Act", "Think big",];
    //const [selectedVoice, setSelectedVoice] = useState(voiceOptions[(Math.floor(Math.random() * voiceOptions.length))]);

    let className = "ResponseGrid";

    if (showDoneButton) {
        className += " ShowDoneButton";
    } else {
        className += " HideDoneButton";
    }

    if (stageResponseCount <= 1) {
        className += " BrainStage1";
    }
    else if (stageResponseCount <= 4) {
        className += " BrainStage2";
    }
    else if (stageResponseCount <= 8) {
        className += " BrainStage3";
    }
    else if (stageResponseCount <= 12) {
        className += " BrainStage4";
    } else {
        className += " BrainStage5";
    }
    // className += " BrainStage4";

    //className += ' BrainStage1';

    // if (stageResponseCount % 2) {
    //     className += " BrainStage4";
    // } else {
    //     className += " BrainStage5";
    // }

    if (brainAnimation) {
        className += " BrainAnimation" + brainAnimation;
    }

    if (brainScores) {
        className += " HasBrainScores";
    }

    const checkpointContent = (
        <div className='CheckpointButton' onClick={handleCheckpointClick} onMouseEnter={handleCheckpointEnter} onMouseLeave={handleCheckpointLeave} style={{ transform: 'scale(' + brainSize + ')' }}>



            <div className='button-fill'>

                <div className='brain'>
                    <img src="keep-going.png" className='brain-img' />
                    <div className='brain-effect-imgs'>
                        <div className='brain-ray-container'><img src="brain-ray.png" className='brain-ray-img' /></div>
                        <div className='brain-sparkles'>
                            <div className='brain-sparkle1-container'><img src="keep-going-sparkle.png" className='brain-sparkle-img brain-sparkle1-img' /></div>
                            <div className='brain-sparkle2-container'><img src="keep-going-sparkle.png" className='brain-sparkle-img brain-sparkle2-img' /></div>
                            <div className='brain-sparkle3-container'><img src="keep-going-sparkle.png" className='brain-sparkle-img brain-sparkle3-img' /></div>
                            <div className='brain-sparkle4-container'><img src="keep-going-sparkle.png" className='brain-sparkle-img brain-sparkle4-img' /></div>
                        </div>
                    </div>
                </div>
                <div className='counter'>{stageResponseCount ? stageResponseCount : ''}</div>
                <div className='daily-counter'>
                    <div className='today-label'>Today</div>
                    <div className='count'>{stageResponseCount + 9}</div>
                </div>


            </div>

            <div className='BrainScores'>
                <div className='scores'>
                    {brainScores && Object.keys(brainScores).map((key, index) => {
                        console.log('brainScore', key, index);
                        let className = 'BrainScore';

                        className += ' ' + toKebabCase(key);

                        return (


                            <div className={className} key={key}>
                                <div className='score-label'>{key}</div>
                                <div className='score-value'>+{brainScores[key]}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );


    if (isLoading || isFakeLoading) {
        return (
            <div className={className}>

                <div className='sheet-heading'>
                    <div className='category-heading'>Responses</div>
                </div>

                <div className='grid-layout'>
                    <div className='sheet'>

                        <div className='respond-heading'>&nbsp;</div>

                        <div className='sheet-grid'>

                            <div className='options LoadingOptions'>
                                <ResponseLoadingGrid count={8}></ResponseLoadingGrid>
                            </div>

                            {!isCheckpointOpen && (
                                <div className='voice-options-container'>

                                    <div className='RefreshButton' onClick={onRefreshRequested}>
                                        <div className='refresh-fill'>
                                            <img src={`refresh-${thought.row.color.toLowerCase()}.png`} className='refresh-icon-img' />
                                        </div>
                                    </div>

                                    {checkpointContent}



                                    {/* <div className='DoneButton' onClick={handleDoneClicked}>
                                        <div className='refresh-fill'>
                                            <img src={`checkmark-${thought.row.color.toLowerCase()}.png`} className='refresh-icon-img' />
                                        </div>
                                    </div> */}

                                </div>
                            )}
                            {isCheckpointOpen && (
                                <div className='ResponseCheckpoint'>

                                    <div className='RefreshButton' onClick={onRefreshRequested}>
                                        <div className='refresh-fill'>
                                            <img src={`refresh-${thought.row.color.toLowerCase()}.png`} className='refresh-icon-img' />
                                        </div>
                                    </div>

                                    <div className='DoneButton' onClick={handleDoneClicked}>
                                        <div className='refresh-fill'>
                                            <img src={`checkmark-${thought.row.color.toLowerCase()}.png`} className='refresh-icon-img' />
                                        </div>
                                    </div>

                                </div>
                            )}


                        </div>

                    </div>

                </div>


            </div>
        );
    }

    return (
        <div className={className}>

            {user.showResponsePop1 && isPop1Open && <WizardPop name={'respond-1'} positions={['left']} shift={[8, 292]} isOpen={isPop1Open} onClose={() => { setIsPop1Open(false); setTimeout(() => handleFollowPops(), 100) }}></WizardPop>}
            {isPopAcknowledgeOpen && <WizardPop name={'respond-acknowledge'} positions={['left']} shift={[-12, 192]} isOpen={isPopAcknowledgeOpen} onClose={() => { setIsPopAcknowledgeOpen(false) }}></WizardPop>}
            {isPopReframeOpen && <WizardPop name={'respond-reframe'} positions={['left']} shift={[-12, 192]} isOpen={isPopReframeOpen} onClose={() => { setIsPopReframeOpen(false) }}></WizardPop>}
            {isPopMotivateOpen && <WizardPop name={'respond-motivate'} positions={['left']} shift={[590, 392]} isOpen={isPopMotivateOpen} onClose={() => { setIsPopMotivateOpen(false); setTimeout(() => setIsPopManifestOpen(true), 200) }}></WizardPop>}
            {isPopManifestOpen && <WizardPop name={'respond-manifest'} positions={['left']} shift={[720, 392]} isOpen={isPopManifestOpen} onClose={() => { setIsPopManifestOpen(false) }}></WizardPop>}

            <div className='sheet-heading'>
                <div className='category-heading'>Responses</div>
            </div>

            <div className='grid-layout'>
                <div className='sheet'>

                    <div className='respond-heading'>&nbsp;</div>

                    <div className='sheet-grid'>

                        {isLoading || isFakeLoading && (
                            <div className='options LoadingOptions'>
                                <ResponseLoadingGrid count={8}></ResponseLoadingGrid>
                            </div>
                        )}

                        {!isLoading && !isFakeLoading && (
                            <div className='options'>

                                {options.map((option, index) => {
                                    let className = 'option ';
                                    const cardId = 'response-option-' + index;
                                    const emojiId = 'response-emoji-' + index;

                                    let areaName = UI.extractWordInBrackets(option);
                                    const splicedLabel = UI.removeBracketedWord(option);

                                    const areaOptions = ["Mood", "Motivation", "Focus", "Ease", "Purpose", "Openness"];
                                    if (areaName === "Possibility") {
                                        areaName = "Openness";
                                    }
                                    if (!areaOptions.includes(areaName)) {
                                        areaName = "Ease";
                                    }

                                    if (markedBadOptions.includes(option)) {
                                        className += ' MarkedBad';
                                    }

                                    return (
                                        <div className={className} id={cardId} onMouseUp={() => handleOptionOff(option, index)} onMouseDown={() => handleOptionPress(option, index)} key={cardId}>

                                            <div className='option-positioning'>
                                                {/* <div className='filler'>
                                                <div className='filler-flex'>
                                                    <div className='filler-fill'></div>
                                                </div>
                                            </div> */}


                                                <div className='emoji-gradient-container'>
                                                    <div className='emoji-gradient'></div>
                                                </div>

                                                <div className='shimmer-container'>
                                                    {/* <div className='shimmer-animation'></div> */}
                                                    {/* <div className='shimmer-animation shimmer-animation-2'></div> */}
                                                </div>

                                                <ResponseReactions emojiId={emojiId} onEmojiClick={handleEmojiClick} option={option} optionIndex={index}></ResponseReactions>

                                                <div className='label'>
                                                    {splicedLabel}
                                                </div>
                                                {/* <img src={`skill-${areaName.toLowerCase()}.png`} className='plant-img icon-img' /> */}
                                                <div className='label-good-find'>
                                                    Good Find!
                                                </div>


                                            </div>

                                        </div>
                                    )
                                })}
                                <div className='clearfix'></div>
                            </div>
                        )}





                        <div className='voice-options-container'>

                            <div className='RefreshButton' onClick={onRefreshRequested}>
                                <div className='refresh-fill'>
                                    <img src={`refresh-${thought.row.color.toLowerCase()}.png`} className='refresh-icon-img' />
                                </div>
                            </div>

                            {checkpointContent}

                            {/* <div className='DoneButton' onClick={handleDoneClicked}>
    <div className='refresh-fill'>
        <img src={`checkmark-${thought.row.color.toLowerCase()}.png`} className='refresh-icon-img' />
    </div>
</div> */}

                        </div>




                    </div>

                </div>

            </div>
        </div >
    );
}

export default ResponseGrid;

function getRandomInteger(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function toKebabCase(str) {
    return str
        .trim()                   // Remove leading/trailing whitespace
        .toLowerCase()            // Convert to lowercase
        .replace(/[^\w\s-]/g, '') // Remove special characters except spaces and hyphens
        .replace(/\s+/g, '-')     // Replace spaces with hyphens
        .replace(/-+/g, '-');     // Replace multiple hyphens with a single hyphen
}

function splitIntoThreeRandomNumbers(total) {
    // Ensure we're working with a valid number
    if (!Number.isInteger(total)) {
        throw new Error("Input must be an integer");
    }

    // Handle edge cases where total is too small
    if (total < 3) {
        return [0, 0, total]; // Or throw an error, depending on desired behavior.
    }

    const maxValue = total - 2;

    // Generate two unique random numbers between 1 and (total-2)
    let split1 = Math.floor(Math.random() * maxValue) + 1; // Corrected max value usage
    let split2 = Math.floor(Math.random() * maxValue) + 1; // Corrected max value usage

    // Ensure they're different, with a maximum number of attempts to avoid infinite loops
    let attempts = 0;
    const maxAttempts = 1000; // Arbitrary limit, adjust as needed

    while (split1 === split2 && attempts < maxAttempts) {
        split2 = Math.floor(Math.random() * maxValue) + 1; // Corrected max value usage
        attempts++;
    }

    // If we failed to find two unique numbers, handle the error
    if (split1 === split2) {
        // If still the same after max attempts, this is a very rare case, but we need to handle it.
        // For simplicity, we could just return an even split. Or return an error.
        const equalSplit = Math.floor(total / 3);
        const remainder = total % 3;
        if (remainder === 0) {
            return [equalSplit, equalSplit, equalSplit];
        } else if (remainder === 1) {
            return [equalSplit, equalSplit, equalSplit + 1];
        } else {
            return [equalSplit, equalSplit + 1, equalSplit + 1];
        }
    }

    // Sort the splits to make calculation easier
    const [smallerSplit, largerSplit] = [split1, split2].sort((a, b) => a - b);

    // Calculate the three parts
    const firstPart = smallerSplit;
    const secondPart = largerSplit - smallerSplit;
    const thirdPart = total - largerSplit;

    return [firstPart, secondPart, thirdPart];
}

function getRandomString(strings) {
    if (!Array.isArray(strings) || strings.length === 0) {
        return null;
    }

    const randomIndex = Math.floor(Math.random() * strings.length);
    return strings[randomIndex];
}